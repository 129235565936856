<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      <span translate="partnersPage.products.requestPriceChangeTitle"> </span>
    </p>
    <nb-icon (click)="dismiss($event)" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <app-data-table [configuration]="dataTableConfiguration"></app-data-table>
    <ng-template #endUserPriceTemplate let-row="row">
      <app-product-pantheon-price
        [selectedPartnerExternalId]="selectedPartnerExternalId$ | async"
        [productExternalId]="row.externalId"
        (pantheonPrice)="checkPantheonPriceValue($event)"
      >
      </app-product-pantheon-price>
    </ng-template>

    <ng-template #doctorsFeePriceTemplate let-row="row">
      <app-product-pantheon-price
        [selectedPartnerExternalId]="selectedPartnerExternalId$ | async"
        [productExternalId]="row.externalId"
        (pantheonPrice)="checkPantheonPriceValue($event)"
      >
      </app-product-pantheon-price>
    </ng-template>

    <ng-template #wholesalePriceTemplate let-row="row">
      <app-product-pantheon-price
        [selectedPartnerExternalId]="selectedPartnerExternalId$ | async"
        [productExternalId]="row.externalId"
        (pantheonPrice)="checkPantheonPriceValue($event)"
      >
      </app-product-pantheon-price>
    </ng-template>

    <hr />
    <div class="row">
      <form
        class="ps-4 pe-4"
        (ngSubmit)="dismiss($event)"
        [formGroup]="changeProductPriceForm"
        novalidate
      >
        <div class="row">
          <div class="form-group col-sm-12 col-lg-12">
            <div class="form-group">
              <div class="row align-items-center mb-1 col-sm-3">
                <span translate="partnersPage.products.newPrices"> </span>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <label
                  class="label col-sm-3 form-control-label mb-0"
                  for="enduserPrice"
                  translate="partnersPage.products.endUserPrice"
                ></label>
                <div class="col-sm-9">
                  <input
                    nbInput
                    fullWidth
                    formControlName="enduserPrice"
                    id="enduserPrice"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <label
                  class="label col-sm-3 form-control-label mb-0"
                  for="doctorsFeePrice"
                  translate="partnersPage.products.doctorsFee"
                ></label>
                <div class="col-sm-9">
                  <input
                    nbInput
                    fullWidth
                    formControlName="doctorsFeePrice"
                    id="doctorsFeePrice"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <label
                  class="label col-sm-3 form-control-label mb-0"
                  for="wholesalePrice"
                  translate="partnersPage.products.wholesalePrice"
                ></label>
                <div class="col-sm-9">
                  <input
                    nbInput
                    fullWidth
                    formControlName="wholesalePrice"
                    id="wholesalePrice"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-lg-12">
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <span> Price valid from: </span>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <label
                  class="label col-sm-3 form-control-label mb-0"
                  for="from"
                  translate="common.from"
                ></label>
                <div class="col-sm-9">
                  <input
                    nbInput
                    fullWidth
                    formControlName="from"
                    id="from"
                    [nbDatepicker]="date"
                  />
                  <nb-datepicker [format]="DATEPICKER_DATE_FORMAT" #date>
                  </nb-datepicker>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center mb-1">
                <label
                  class="label col-sm-3 form-control-label mb-0"
                  for="to"
                  translate="common.to"
                ></label>
                <div class="col-sm-9">
                  <input
                    nbInput
                    fullWidth
                    formControlName="to"
                    id="to"
                    [nbDatepicker]="date"
                  />
                  <nb-datepicker [format]="DATEPICKER_DATE_FORMAT" #date>
                  </nb-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>
