import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NOTIFICATION_EMAILS_CONSTANTS } from '@app/app.constants';
import { NotificationsProfilesUtilsService } from '@app/modules/notification-profiles/notification-profiles-utils.service';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import {
  ModalMode,
  NotificationProfileModel,
  Partner,
  TemplateType,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { getRecipientsString } from '@core/utils/core.util';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { environment } from '@root/environments/environment';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { NotificationProfileDialogComponent } from '../components/notification-profile-dialog/notification-profile-dialog.component';
import { NotificationProfileDialogContext } from '../notification-profile.types';
import { Store } from '@ngxs/store';
import { LanguageState } from '@core/store/languages/languages.state';

@Component({
  selector: 'app-notification-profiles-list',
  templateUrl: 'notification-profiles-list.component.html',
  styleUrls: ['notification-profiles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationProfilesListComponent implements OnInit, OnDestroy {
  @Input() doctor: Partner;
  @Input() partner: Partner;
  @Input() serviceName: string;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;
  @ViewChild('recipientsTemplate', { static: true })
  recipientsTemplate: TemplateRef<any>;

  rows$: Observable<NotificationProfileModel[]>;
  profileRows$: Observable<NotificationProfileModel[]>;
  isLoading$ = new BehaviorSubject<boolean>(true);
  isCreateButtonHidden: boolean;
  getRecipientsString = getRecipientsString;
  existingTemplates: string[];
  TemplateType = TemplateType;

  dataTableConfiguration: DataTableConfiguration;

  missingNotificationProfiles$ = new BehaviorSubject<string[]>([]);

  public resultReferenceLinkEmail = environment.resultReferenceLinkEmail;

  private readonly log = new Logger('NotificationProfilesListComponent');
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private dialogRef: NbDialogRef<any>,
    private nbDialogService: NbDialogService,
    private partnersUtilsService: PartnersUtilsService,
    private notificationProfilesUtils: NotificationsProfilesUtilsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.profileRows$ = this.partnersUtilsService.getDoctorNotificationProfiles(
      this.doctor.id
    );

    this.notificationProfilesUtils
      .findMissingNotificationProfileTypes$(this.doctor.id, this.serviceName)
      .subscribe((profiles) =>
        this.missingNotificationProfiles$.next(profiles)
      );

    this.rows$ = combineLatest([
      this.profileRows$,
      this.missingNotificationProfiles$.pipe(
        map((profiles) =>
          profiles.map(
            (profile) =>
              ({
                name: profile,
                recipients: null,
              }) as NotificationProfileModel
          )
        )
      ),
    ]).pipe(
      map(([a, b]) => [...a, ...b]),
      takeUntil(this.unsubscribe$),
      map((profiles) =>
        profiles.sort((a, b) => (a.name !== 'Result reference link' ? -1 : 1))
      ),
      tap(() => this.isLoading$.next(false))
    );

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      tableHeadClasses: '',
      massActions: [
        {
          type: DataTableActionType.BUTTON,
          name: 'notificationProfilesPage.tooltips.editNotificationProfiles',
          icon: 'edit-outline',
          permission:
            'partners.doctors.notificationProfiles.buttons.editAndCreate',
          action: this.createOrEditNotificationProfiles.bind(this),
          invisible: () => !!this.missingNotificationProfiles$.value.length,
        },
        {
          type: DataTableActionType.BUTTON,
          name: 'notificationProfilesPage.tooltips.createNotificationProfiles',
          icon: 'plus-outline',
          permission:
            'partners.doctors.notificationProfiles.buttons.editAndCreate',
          action: this.createOrEditNotificationProfiles.bind(this),
          invisible: () => !this.missingNotificationProfiles$.value.length,
        },
      ],
      columns: [
        {
          label: 'notificationProfilesPage.tableColumns.name',
          propertyTemplate: this.nameTemplate,
        },
        {
          label: 'notificationProfilesPage.tableColumns.recipients',
          propertyTemplate: this.recipientsTemplate,
        },
      ],
      singleActions: [
        {
          type: DataTableActionType.BUTTON,
          name: 'notificationProfilesPage.tooltips.editNotificationProfile',
          icon: 'edit-outline',
          permission:
            'partners.doctors.notificationProfiles.buttons.editAndCreate',
          invisible: (row) =>
            row.name === TemplateType.CustomerInviteToOnlineReport ||
            row.name === TemplateType.PatientConsent ||
            row.recipients === null,
          action: this.editNotificationProfile.bind(this),
        },
      ],
      rows$: this.rows$,
    };
  }

  async createOrEditNotificationProfiles(event: MouseEvent) {
    event.preventDefault();
    this.rows$.pipe(first()).subscribe(async (notificationProfiles) => {
      const missingNotificationProfiles = await firstValueFrom(
        this.notificationProfilesUtils.findMissingNotificationProfileTypes$(
          this.doctor.id,
          this.serviceName
        )
      );

      this.nbDialogService.open(NotificationProfileDialogComponent, {
        context: {
          modalMode:
            missingNotificationProfiles && missingNotificationProfiles.length
              ? ModalMode.Create
              : ModalMode.Edit,
          doctor: this.doctor,
          partnerLanguage: this.store.selectSnapshot(
            LanguageState.getLanguageById
          )(this.partner.languageIds[0]).name,
          notificationProfiles,
          serviceName: this.serviceName,
          missingNotificationProfiles,
        } as NotificationProfileDialogContext as any,
      });
    });
  }

  editNotificationProfile(
    event: MouseEvent,
    notificationProfile: NotificationProfileModel
  ) {
    event.preventDefault();
    this.nbDialogService.open(NotificationProfileDialogComponent, {
      context: {
        modalMode: ModalMode.Edit,
        doctor: this.doctor,
        partnerLanguage: this.store.selectSnapshot(
          LanguageState.getLanguageById
        )(this.partner.languageIds[0]).name,
        notificationProfiles: [notificationProfile],
        serviceName: this.serviceName,
        missingNotificationProfiles: null,
      } as NotificationProfileDialogContext as any,
    });
  }

  dismiss() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  getTemplateLanguageString(profile: NotificationProfileModel) {
    const template =
      NOTIFICATION_EMAILS_CONSTANTS.resultReferenceLinkTemplates[
        profile.templateId
      ];
    return template ? `(${template})` : '';
  }
}
