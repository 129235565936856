import {Product} from '@core/core.types';

export const PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME = 'partnerProductConfigurations';

export class SetPartnersProductConfigurations {
  static type = `[${PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME}] SetPartnersProductConfigurations`;

  constructor(public partnerId: string,
              public productConfigurations: string[]) {
  }
}

export class AddProductConfiguration {
  static type = `[${PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME}] AddProductConfiguration`;

  constructor(public product: Product) {
  }
}

export class RemoveProductConfiguration {
  static type = `[${PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME}] RemoveProductConfiguration`;

  constructor(public productId: string) {
  }
}
