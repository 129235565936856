import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  DOCTORS_PARTNER_TYPES,
  FIRST_LEVEL_PARTNER_TYPES,
  NIFTY_APPLICATIONID_KEY,
} from '@app/app.constants';
import {
  AddPartnerDoctorAction,
  UpdatePartnerDoctorAction,
} from '@app/modules/@core/store/partners/partners.actions';
import {
  LoadUserByIdAction,
  UpdateAccountPasswordAction,
} from '@app/modules/@core/store/user-accounts/user-accounts.actions';
import { UserAccountsState } from '@app/modules/@core/store/user-accounts/user-accounts.state';
import { CoreUtilsService } from '@app/modules/@core/utils/core-utils.service';
import {
  GENDERS,
  ModalMode,
  Partner,
  PartnerType,
  TemplateType,
  UserAccount,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { CountriesState } from '@core/store/countries/countries.state';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { NbDialogRef } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { environment } from '@root/environments/environment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  firstValueFrom,
  forkJoin,
  of,
} from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Util, validatePassword } from 'src/app/modules/@core/utils/core.util';
import { NOTIFICATION_EMAIL_SENDER } from '../../../../app.constants';
import { RecipientModel } from '../../../@core/core.types';
import { getTemplateId } from '../../../@core/utils/partner.util';
import { NotificationsProfilesUtilsService } from '../../../notification-profiles/notification-profiles-utils.service';
import { PartnersUtilsService } from '../../partners-utils.service';

@UntilDestroy()
@Component({
  selector: 'app-doctor-dialog',
  templateUrl: 'doctor-dialog.component.html',
  styleUrls: ['doctor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorDialogComponent implements OnInit {
  @ViewChild('deleteRowTemplate', { static: true })
  deleteRowTemplate: TemplateRef<any>;
  @ViewChild('emailTemplate', { static: true }) emailTemplate: TemplateRef<any>;

  @Input() modalMode: ModalMode;
  @Input() editItem: Partner;
  @Input() partnerId: string;
  @Input() partner: Partner;
  @Input() partnerRegion: string;
  @Input() serviceName: string;
  @Input() partnerLanguage: string;

  GENDERS = GENDERS;
  ModalMode = ModalMode;

  formDirty: boolean;
  doctorForm: any = null;
  propertiesForm: any = null;
  regionForm: any = null;

  isRegionShown = false;

  isLoading$ = new BehaviorSubject(false);
  doctorUserAccount$ = new BehaviorSubject<UserAccount>(null);
  employeePartnerTypes$: Observable<PartnerType[]>;
  serviceName$ = new ReplaySubject<string>(1);
  partnerId$ = new ReplaySubject<string>(1);
  showDifferentRegion$ = new BehaviorSubject<boolean>(false);
  partnerRegions$: Observable<string[]>;

  private readonly log = new Logger(this.constructor.name);
  private partnerTypesLoaded$: BehaviorSubject<string>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    private ngZone: NgZone,
    private dialogRef: NbDialogRef<any>,
    private translate: TranslateService,
    private coreUtils: CoreUtilsService,
    private partnersUtils: PartnersUtilsService,
    private notificationsProfilesUtils: NotificationsProfilesUtilsService
  ) {}

  get modalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'partnersPage.doctorsForm.createDoctorTitle';
    } else if (this.modalMode === ModalMode.Edit) {
      return 'partnersPage.doctorsForm.editDoctorTitle';
    }
    return '';
  }

  get closeModalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'common.buttons.btnCreate';
    } else if (this.modalMode === ModalMode.Edit) {
      return 'common.buttons.btnSave';
    }
    return '';
  }

  ngOnInit(): void {
    this.partnerId$.next(this.partnerId);
    this.partnerRegions$ = this.loadPartnersRegions();
    this.serviceName$.next(this.serviceName);
    if (this.modalMode === ModalMode.Create) {
      this.partnerTypesLoaded$ = new BehaviorSubject(null);
    }
    this.createForm(
      this.modalMode === ModalMode.Edit ? this.editItem : null,
      this.partner
    );
    this.employeePartnerTypes$ = combineLatest([
      this.store.select(PartnerTypeState.getPartnerTypesList),
      this.partnersUtils.getPartnerById$(this.partnerId),
    ]).pipe(
      map(([partnerTypes, partner]) => {
        const employeePartnerTypes = [];
        const partnerType =
          partnerTypes && partner
            ? partnerTypes.find((pt) => pt.id === partner.partnerTypeId)
            : null;
        if (partnerType) {
          if (
            [
              FIRST_LEVEL_PARTNER_TYPES.private,
              FIRST_LEVEL_PARTNER_TYPES.privateClinic,
              FIRST_LEVEL_PARTNER_TYPES.public,
              FIRST_LEVEL_PARTNER_TYPES.publicHospital,
            ].indexOf(partnerType.type) > -1
          ) {
            return partnerTypes.filter(
              (pt) =>
                [
                  DOCTORS_PARTNER_TYPES.physician,
                  DOCTORS_PARTNER_TYPES.medicalAdministrator,
                ].indexOf(pt.type) > -1
            );
          }
          if (
            [FIRST_LEVEL_PARTNER_TYPES.laboratory].indexOf(partnerType.type) >
            -1
          ) {
            return partnerTypes.filter(
              (pt) =>
                [DOCTORS_PARTNER_TYPES.collectionPoint].indexOf(pt.type) > -1
            );
          }
          if (
            [FIRST_LEVEL_PARTNER_TYPES.referral].indexOf(partnerType.type) > -1
          ) {
            return partnerTypes.filter(
              (pt) =>
                [DOCTORS_PARTNER_TYPES.referringPhysician].indexOf(pt.type) > -1
            );
          }
        }
        return employeePartnerTypes;
      })
    );
  }

  formatPartnerLabel(partnerType: PartnerType) {
    return `${partnerType.type}`;
  }

  async createForm(doctor: Partner, partner: Partner) {
    let sexProp: any;
    let titleProp: any;
    let ratingProp: any;
    let regionProp: any;

    let companyProp: any;
    let addressProp: any;
    let cityProp: any;
    let zipProp: any;

    if (doctor) {
      sexProp = doctor.properties.find((p) => p.key.toLowerCase() === 'sex');
      sexProp = sexProp ? sexProp.value : '';

      titleProp = doctor.properties.find(
        (p) => p.key.toLowerCase() === 'title'
      );
      titleProp = titleProp ? titleProp.value : '';

      ratingProp = doctor.properties.find(
        (p) => p.key.toLowerCase() === 'rating'
      );
      ratingProp = ratingProp ? ratingProp.value : 0;

      regionProp = doctor.properties.find(
        (p) => p.key.toLowerCase() === 'region'
      );
      regionProp = regionProp ? regionProp.value : '';

      companyProp = doctor.properties.find(
        (p) => p.key.toLowerCase() === 'company'
      );
      companyProp = companyProp ? companyProp.value : '';

      addressProp = doctor.properties.find(
        (p) => p.key.toLowerCase() === 'address'
      );
      addressProp = addressProp ? addressProp.value : '';

      cityProp = doctor.properties.find((p) => p.key.toLowerCase() === 'city');
      cityProp = cityProp ? cityProp.value : '';

      zipProp = doctor.properties.find((p) => p.key.toLowerCase() === 'zip');
      zipProp = zipProp ? zipProp.value : '';

      this.showDifferentRegion$.next(this.partnerRegion ? true : false);

      this.isRegionShown = regionProp !== this.partnerRegion;
    }

    this.propertiesForm = this.formBuilder.array([
      this.formBuilder.group({
        key: ['Sex'],
        value: [doctor ? sexProp : '', [Validators.required]],
      }),
      this.formBuilder.group({
        key: ['Title'],
        value: [doctor ? titleProp : ''],
      }),
      this.formBuilder.group({
        key: ['Rating'],
        value: [
          doctor ? ratingProp : '',
          [Validators.compose([Validators.min(0), Validators.max(5)])],
        ],
      }),
      this.formBuilder.group({
        key: ['Region'],
        value: [doctor ? regionProp : this.partnerRegion],
      }),
      this.formBuilder.group({
        key: ['Company'],
        value: [doctor ? companyProp : partner.name],
      }),
      this.formBuilder.group({
        key: ['Address'],
        value: [doctor ? addressProp : partner.address.street],
      }),
      this.formBuilder.group({
        key: ['City'],
        value: [doctor ? cityProp : partner.address.city],
      }),
      this.formBuilder.group({
        key: ['Zip'],
        value: [doctor ? zipProp : partner.address?.zip],
      }),
      this.formBuilder.group({
        key: ['Country'],
        value: partner.address.country,
      }),
    ]);

    this.regionForm = this.formBuilder.group({
      differentRegion: [doctor ? regionProp !== this.partnerRegion : false],
    });

    const id = Util.CreateGuid();
    this.doctorForm = this.formBuilder.group(
      Object.assign(
        {
          id: [
            doctor ? doctor.id : id,
            [Validators.compose([Validators.required])],
          ],
          partnerId: [
            doctor ? doctor.id : id,
            [Validators.compose([Validators.required])],
          ],
          name: [
            doctor ? doctor.name : '',
            [Validators.compose([Validators.required])],
          ],
          email: [doctor ? doctor.email : ''],
          partnerTypeId: [
            doctor ? doctor.partnerTypeId : '',
            [Validators.compose([Validators.required])],
          ],
          phoneNumber: [doctor ? doctor.phoneNumber : '', Validators.required],
          notes: [doctor ? doctor.notes : '', []],
          username: [
            {
              value: doctor ? doctor.username : '',
              disabled: this.modalMode === ModalMode.Edit,
            },
            this.modalMode === ModalMode.Create
              ? [
                  Validators.compose([
                    Validators.required,
                    Validators.pattern(Util.GetEmailPattern()),
                  ]),
                ]
              : [],
          ],
          password: [
            doctor ? doctor.password : '',
            this.modalMode === ModalMode.Create
              ? [Validators.compose([Validators.required, validatePassword])]
              : [validatePassword],
          ],
          properties: this.propertiesForm,
          region: this.regionForm,
        },
        doctor
          ? {
              barcodeRanges: [(doctor as any).barcodeRanges],
              notificationProfiles: [(doctor as any).notificationProfiles],
              policyIds: [(doctor as any).policyIds],
              productConfigurations: [(doctor as any).productConfigurations],
              subPartners: [(doctor as any).subPartners],
              userAccounts: [(doctor as any).userAccounts],
              registered: [(doctor as any).registered],
              usedForTesting: [(doctor as any).usedForTesting],
            }
          : {
              barcodeRanges: [[]],
            }
      )
    );
    this.setDoctorUserAccountValues(doctor);

    const showOrHideRegion = (show: boolean) => {
      if (show) {
        this.isRegionShown = true;
        this.cp('Region').setValue('');
      } else {
        this.isRegionShown = false;
        this.cp('Region').setValue(this.partnerRegion);
      }
    };

    this.cc('differentRegion')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((differentRegion: boolean) => {
          showOrHideRegion(differentRegion);
        })
      )
      .subscribe();

    if (this.modalMode === ModalMode.Create) {
      this.partnerTypesLoaded$
        .pipe(
          untilDestroyed(this),
          take(1),
          filter((loaded) => !!loaded),
          tap((physicianId: string) => {
            Util.UpdateComponentView(this.cd, this.ngZone, () => {
              this.doctorForm.controls.partnerTypeId.setValue(physicianId);
            });
          })
        )
        .subscribe();
    }

    this.cp('Country').disable();
  }

  public c(control: string): any {
    return this.doctorForm.controls[control];
  }

  public cp(control: string): any {
    return this.propertiesForm.controls.find(
      (c) => c.controls.key.value === control
    ).controls.value;
  }

  public cc(control: string): any {
    return this.regionForm.controls[control];
  }

  dismiss() {
    this.dialogRef.close();
  }

  async close() {
    if (this.doctorForm.invalid) {
      Util.MarkFormGroupTouched(this.doctorForm);
      return false;
    }
    this.isLoading$.next(true);
    const formValue = this.doctorForm.getRawValue();
    const employeePartnerTypes = await this.employeePartnerTypes$
      .pipe(take(1))
      .toPromise();
    const selectedEmployeePartnerType = employeePartnerTypes.filter(
      (pt) => pt.id === formValue.partnerTypeId
    );
    const saveActions = [];
    if (this.modalMode === ModalMode.Edit) {
      saveActions.push(
        this.store.dispatch(new UpdatePartnerDoctorAction(formValue))
      );
      if (formValue.password) {
        const userAccount = await this.doctorUserAccount$
          .pipe(take(1))
          .toPromise();
        saveActions.push(
          this.store.dispatch(
            new UpdateAccountPasswordAction(userAccount.id, formValue.password)
          )
        );
      }
    } else {
      formValue.email = formValue.username;
      formValue.applicationId = NIFTY_APPLICATIONID_KEY;
      saveActions.push(
        this.store.dispatch(
          new AddPartnerDoctorAction(this.partnerId, formValue)
        )
      );
    }

    try {
      await forkJoin(saveActions).toPromise();
      this.log.debug(`Doctor saved`);
      if (
        selectedEmployeePartnerType[0].type.toLowerCase() ===
        DOCTORS_PARTNER_TYPES.physician.toLowerCase()
      ) {
        const recipients: RecipientModel[] = [
          {
            recipient: formValue.email,
            recipientName: formValue.name,
          },
        ];

        await this.createNotificationProfile(recipients, formValue.id);
      }
      this.dismiss();
    } catch (e) {
      const errorMessage =
        e && e.error && e.error.error
          ? e.error && e.error.error
          : this.translate.instant('common.errors.error');
      this.coreUtils.showErrowMessage(errorMessage);
    }
    this.isLoading$.next(false);
  }

  private async setDoctorUserAccountValues(doctor: Partner) {
    if (doctor && doctor.userAccounts && doctor.userAccounts.length) {
      const accountId = doctor.userAccounts[0].id;
      const doctorUserAccount = await this.store
        .dispatch(new LoadUserByIdAction(accountId, true))
        .pipe(
          switchMap(() => this.store.select(UserAccountsState.getUserById)),
          map((filterById) => filterById(accountId)),
          take(1)
        )
        .toPromise();
      if (doctorUserAccount) {
        this.doctorUserAccount$.next(doctorUserAccount);
        this.c('username').setValue(doctorUserAccount.email);
      }
    }
  }

  private async createNotificationProfile(
    recipients: RecipientModel[],
    doctorId: string
  ) {
    this.isLoading$.next(true);
    const serviceName = await this.serviceName$.pipe(take(1)).toPromise();
    const nps = [];

    const sender = NOTIFICATION_EMAIL_SENDER[serviceName];

    const senderName = `${serviceName} TEST`;

    const missingNotificationProfilesTypes = await firstValueFrom(
      this.notificationsProfilesUtils.findMissingNotificationProfileTypes$(
        doctorId,
        this.serviceName
      )
    );

    for (const templateType of missingNotificationProfilesTypes) {
      let recipientsTemp = recipients;
      if (
        templateType === TemplateType.CustomerInviteToOnlineReport ||
        templateType === TemplateType.PatientConsent
      ) {
        recipientsTemp = [
          {
            recipientName: this.doctorForm.value.name,
            recipient: environment.resultReferenceLinkEmail,
          },
        ];
      }

      nps.push({
        id: Util.CreateGuid(),
        sender,
        senderName,
        recipients: recipientsTemp,
        name: templateType,
        templateId: this.getTemplateIdBasedOnTemplateType(
          templateType,
          serviceName
        ),
        applicationId: NIFTY_APPLICATIONID_KEY,
      });
    }

    const saveItem$ =
      this.notificationsProfilesUtils.createPartnerNotificationProfiles(
        doctorId,
        nps
      );

    await saveItem$.toPromise();
    this.isLoading$.next(false);
  }

  private getTemplateIdBasedOnTemplateType(
    templateType: string,
    serviceName: string
  ): string {
    if (templateType === TemplateType.CustomerInviteToOnlineReport) {
      return this.partnersUtils.getResultReferenceLinkTemplateByLanguage(
        this.partnerLanguage
      );
    } else if (templateType === TemplateType.CustomerFormInvite) {
      return this.partnersUtils.getPatientFormLinkTemplateByLanguage(
        this.partnerLanguage
      );
    } else {
      return getTemplateId(serviceName);
    }
  }

  private loadPartnersRegions(): Observable<string[]> {
    return this.partnerId$.pipe(
      switchMap((partnerId) => {
        if (partnerId) {
          return this.partnersUtils
            .getPartnerById$(partnerId)
            .pipe(
              switchMap((partner) =>
                this.store
                  .select(CountriesState.getCountryRegions)
                  .pipe(
                    map((getCountryRegions) =>
                      getCountryRegions(partner.address.country)
                    )
                  )
              )
            );
        }
        return of([]);
      })
    );
  }
}
