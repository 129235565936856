import { Injectable } from '@angular/core';
import { ServiceType } from '@app/app.constants';
import {
  LumipeekTemplateTypes,
  NotificationProfileModel,
  TemplateTypes,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { NotificationProfilesService } from '@core/services/notification-profiles.service';
import { PartnersService } from '@core/services/partners.service';
import { NotificationProfileState } from '@core/store/notification-profiles/notification-profiles.state';
import {
  LoadPartnerById,
  LoadPartnersByIds,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import {
  AddNotificationProfilesAction,
  LoadNotificationProfilesMultiAction,
} from '../@core/store/notification-profiles/notification-profiles.actions';

@Injectable({
  providedIn: 'root',
})
export class NotificationsProfilesUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private store: Store,
    public notificationProfilesService: NotificationProfilesService,
    private partnersService: PartnersService
  ) {}

  findMissingNotificationProfileTypes$(doctorId, serviceName = 'NIPT') {
    return this.store.dispatch(new LoadPartnerById(doctorId)).pipe(
      switchMap(() =>
        this.store
          .select(PartnersState.getPartnerById)
          .pipe(map((findById) => findById(doctorId)))
      ),
      switchMap((doctor) =>
        this.store
          .dispatch(
            new LoadNotificationProfilesMultiAction(doctor.notificationProfiles)
          )
          .pipe(
            switchMap(() =>
              this.store
                .select(NotificationProfileState.getNotificationProfiles)
                .pipe(
                  map((findByIds) => findByIds(doctor.notificationProfiles))
                )
            )
          )
      ),
      map((notificationProfiles) => {
        const templateTypes =
          serviceName === ServiceType.LUMIPEEK ||
          serviceName === ServiceType.NIPTGENOMICS ||
          serviceName === ServiceType.MOJPRENATALNITEST
            ? LumipeekTemplateTypes
            : TemplateTypes;

        return templateTypes.filter(
          (type) =>
            !notificationProfiles.find((profile) => profile.name === type)
        );
      })
    );
  }

  createPartnerNotificationProfiles(
    partnerId: string,
    notificationProfiles: NotificationProfileModel[]
  ): Observable<NotificationProfileModel[]> {
    this.log.debug('create partner notification profiles');
    if (!partnerId || !notificationProfiles || !notificationProfiles.length) {
      return of(null);
    }
    return forkJoin(
      notificationProfiles.map((np) =>
        this.notificationProfilesService.generateNotificationProfile(np).pipe(
          switchMap(() =>
            this.store.dispatch(new AddNotificationProfilesAction([np]))
          ),
          map(() => np)
        )
      )
    ).pipe(
      switchMap((nps) =>
        forkJoin(
          nps.map((np) =>
            this.partnersService
              .assignNotificationProfileToPartner(partnerId, np.id)
              .pipe(map(() => np))
          )
        ).pipe(
          delay(500),
          switchMap(() =>
            this.store.dispatch(new LoadPartnersByIds([partnerId], true))
          )
        )
      )
    );
  }
}
