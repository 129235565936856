import { Injectable } from '@angular/core';
import { LoadProductConfigurationById } from '@app/modules/@core/store/product-configuration/product-configuration.actions';
import { ProductConfiguration } from '@core/core.types';
import { PartnersService } from '@core/services/partners.service';
import { ProductsService } from '@core/services/products.service';
import { SetPartnerProductsConfigurationsAction } from '@core/store/partners/partners.actions';
import { ProductConfigurationsState } from '@core/store/product-configuration/product-configuration.state';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
  AddProductConfiguration,
  PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME,
  RemoveProductConfiguration,
  SetPartnersProductConfigurations,
} from './partner-product-configurations.action';

export interface PartnersProductConfigurationsStateModel {
  partnerId: string;
  productConfigurations: string[];
}

@State<PartnersProductConfigurationsStateModel>({
  name: PARTNER_PRODUCT_CONFIGURATIONS_STATE_NAME,
  defaults: {
    productConfigurations: [],
    partnerId: null,
  },
})
@Injectable()
export class PartnerProductConfigurationsState {
  constructor(
    private productsService: ProductsService,
    private partnersService: PartnersService,
    private store: Store
  ) {}

  @Selector()
  static getProductConfigurations(
    state: PartnersProductConfigurationsStateModel
  ) {
    return state.productConfigurations;
  }

  @Action(SetPartnersProductConfigurations)
  setPartnersProductConfigurations(
    { patchState }: StateContext<PartnersProductConfigurationsStateModel>,
    state: SetPartnersProductConfigurations
  ) {
    return of(
      patchState({
        ...state,
      })
    );
  }

  @Action(AddProductConfiguration)
  addProductConfiguration(
    {
      patchState,
      getState,
      dispatch,
    }: StateContext<PartnersProductConfigurationsStateModel>,
    { product }: AddProductConfiguration
  ) {
    const { productConfigurations, partnerId } = getState();

    const createProductConfigurationAndAssignToPartner = () => {
      const productConfigurationId = Util.CreateGuid();
      return this.productsService
        .createProductConfiguration(
          productConfigurationId,
          product.id,
          partnerId
        )
        .pipe(
          switchMap(() =>
            this.partnersService
              .setProductConfiguratonToPartner(
                partnerId,
                productConfigurationId,
                true
              )
              .pipe(
                switchMap(() => {
                  const updatedProductConfigurations = [
                    ...productConfigurations,
                    productConfigurationId,
                  ];

                  return forkJoin([
                    dispatch(
                      new LoadProductConfigurationById(productConfigurationId)
                    ),
                    dispatch(
                      new SetPartnerProductsConfigurationsAction(
                        partnerId,
                        updatedProductConfigurations
                      )
                    ),
                    of(
                      patchState({
                        productConfigurations: updatedProductConfigurations,
                      })
                    ),
                  ]);
                })
              )
          )
        );
    };

    if (productConfigurations && productConfigurations.length <= 0) {
      return createProductConfigurationAndAssignToPartner();
    }

    return forkJoin(
      productConfigurations.map((productConfigurationId) =>
        this.store
          .select(ProductConfigurationsState.getProductConfigurationById)
          .pipe(
            map((filterByProductConfigurationId) =>
              filterByProductConfigurationId(productConfigurationId)
            ),
            filter((pc: ProductConfiguration) => !!pc),
            take(1)
          )
      )
    ).pipe(
      switchMap((takenProductConfigurations: ProductConfiguration[]) => {
        for (const productConfiguration of takenProductConfigurations) {
          if (productConfiguration.productId === product.id) {
            return throwError('This product already exists in collection!');
          }
        }

        return createProductConfigurationAndAssignToPartner();
      })
    );
  }

  @Action(RemoveProductConfiguration)
  removeProductConfiguration(
    {
      patchState,
      getState,
      dispatch,
    }: StateContext<PartnersProductConfigurationsStateModel>,
    { productId }: RemoveProductConfiguration
  ) {
    const { partnerId, productConfigurations } = getState();

    const itemIndex = productConfigurations.indexOf(productId);
    if (itemIndex >= 0) {
      return this.partnersService
        .setProductConfiguratonToPartner(partnerId, productId, false)
        .pipe(
          switchMap(() =>
            this.productsService.deleteProductConfiguration(productId).pipe(
              switchMap(() => {
                const updatedProductConfigurations = productConfigurations
                  .slice(0, itemIndex)
                  .concat(productConfigurations.slice(itemIndex + 1));

                return forkJoin([
                  dispatch(
                    new SetPartnerProductsConfigurationsAction(
                      partnerId,
                      updatedProductConfigurations
                    )
                  ),
                  of(
                    patchState({
                      productConfigurations: updatedProductConfigurations,
                    })
                  ),
                ]);
              })
            )
          )
        );
    }

    return throwError(`Item doesn't exists in the collection!`);
  }
}
