import { ModalMode, Partner, Product } from '../@core/core.types';

export interface PartnerDialogContext {
  modalMode: ModalMode;
  editItem?: Partner;
}

export interface DoctorDialogContext {
  partnerId: string;
  modalMode: ModalMode;
  editItem?: Partner;
  partnerRegion?: string;
  serviceName?: string;
}

export interface PartnerProductDialogContext {
  partner: Partner;
}

export interface ChangeProductPriceDialogContex {
  product: Product;
  selectedPartnerExternalId: string;
}

export const PlasmaLabLocations = [
  {
    value: 'Mexico',
    label: 'Monterrey (Mexico)',
  },
  {
    value: '',
    label: 'Not Required',
  },
];
