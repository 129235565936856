import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, take } from 'rxjs/operators';
import { Logger } from '../../../@core/services/logger.service';
import { ProductUtilsService } from '../../../@core/services/product-utils.service';
import { CoreUtilsService } from '../../../@core/utils/core-utils.service';

@Component({
  selector: 'app-product-pantheon-price',
  templateUrl: './product-pantheon-price.component.html',
  styleUrls: ['./product-pantheon-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPantheonPriceComponent implements OnInit {
  @Output() pantheonPrice = new EventEmitter<any>();
  productExternalId$ = new ReplaySubject<string>(1);
  price = 'N/A';

  private selectedPartnerExternalId$ = new ReplaySubject<string>(1);
  private priceContractList$: Observable<any>;
  private log = new Logger('ProductPantheonPriceComponent');

  constructor(
    private coreUtilsService: CoreUtilsService,
    private productUtils: ProductUtilsService,
    private changeDetection: ChangeDetectorRef
  ) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('productExternalId')
  set setProductExternalId(value: string) {
    this.productExternalId$.next(value);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedPartnerExternalId')
  set setSelectedPartnerExternalId(value: any) {
    this.selectedPartnerExternalId$.next(value);
  }

  ngOnInit() {
    this.priceContractList$ = this.selectedPartnerExternalId$.pipe(
      switchMap((partnerExternalId) => {
        if (partnerExternalId) {
          return this.productUtils.getPriceContactsFromPantheonByPartnerExternalId$(
            partnerExternalId
          );
        }
        return of(null);
      })
    );

    this.getPriceForProduct();
  }

  async getPriceForProduct() {
    this.log.debug('getriceForProduct');

    combineLatest([this.priceContractList$, this.productExternalId$])
      .pipe(
        take(1),
        map(([priceContractList, productExternalId]) => {
          if (priceContractList && priceContractList.length) {
            const priceContract =
              this.coreUtilsService.getPriceContractForProduct(
                priceContractList,
                productExternalId
              );
            if (priceContract.length) {
              this.price =
                priceContract[0].retailPrice + ' ' + priceContract[0].currency;
            }
          }
          this.pantheonPrice.emit({
            price: this.price,
          });
          this.changeDetection.markForCheck();
        })
      )
      .subscribe();
  }
}
