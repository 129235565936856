<app-data-table
  *ngIf="dataTableConfiguration"
  [configuration]="dataTableConfiguration"
  (selectedRowsChange)="onSelectedRowsChange($event)"
  [loading]="isLoading"
></app-data-table>

<ng-template #nameTemplate let-doctor="row">
  {{ doctor?.name }}
  @if (doctor?.deactivated) {
    <span
      class="badge bg-danger ms-2"
      [nbTooltip]="'partnersPage.tooltips.doctorDeactivated' | translate"
      >{{ 'partnersPage.infoBadges.deactivated' | translate }}
    </span>
  }
</ng-template>

<ng-template #emailTemplate let-doctor="row">
  <ng-container
    *ngIf="
      resolveDoctorNotificationEmails$(doctor) | async as emails;
      else loadingColumn
    "
  >
    <span [innerHtml]="emails"></span>
  </ng-container>
</ng-template>

<ng-template #partnerTypeTemplate let-doctor="row">
  <span *ngIf="doctor?.partnerTypeId">
    {{ partnerType$(doctor.partnerTypeId) | async }}
  </span>
</ng-template>

<ng-template #loadingColumn> -- </ng-template>
