<app-data-table
  [configuration]="dataTableConfiguration"
  [loading]="isLoading"
></app-data-table>

<ng-template #nameTemplate let-row="row">
  {{ row.name }}
  <span
    class="badge bg-warning ms-2"
    *ngIf="row.deactivated"
    [nbTooltip]="'Product is deactivated in configuration'"
    >{{ 'partnersPage.productDeactivated' | translate }}</span
  >
  <span
    class="badge bg-danger ms-2"
    *ngIf="!(isProductAvailable(row.id) | async)"
    [nbTooltip]="'Product not available for this country'"
    >{{ 'partnersPage.productNotAvailable' | translate }}</span
  >
</ng-template>

<ng-template #endUserPriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    productExternalId="{{ row.externalId3 }}"
  >
  </app-product-pantheon-price>
</ng-template>
<ng-template #doctorsFeePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    productExternalId="{{ row.externalId2 }}"
  >
  </app-product-pantheon-price>
</ng-template>
<ng-template #wholesalePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    productExternalId="{{ row.externalId }}"
  >
  </app-product-pantheon-price>
</ng-template>
<ng-template #onlineFeePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    [productExternalId]="row.externalId"
  >
  </app-product-pantheon-price>
</ng-template>
