<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">{{ modalTitle | translate }}</p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form
      class="ps-4 pe-4"
      (ngSubmit)="close()"
      [formGroup]="partnerForm"
      novalidate
    >
      <p class="caption mb-1" translate="common.titleBaseInformation"></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="name"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.name"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="name"
                name="name"
                id="name"
                placeholder="{{ 'common.placeholders.name' | translate }}"
                [status]="
                  c('name').dirty
                    ? c('name').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('name').invalid && c('name').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container *ngIf="c('name').invalid && c('name').touched">
                <span
                  class="caption status-danger"
                  *ngIf="c('name').errors?.required"
                  translate="partnersPage.errors.nameIsRequired"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="language"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.language"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="languages$ | async as languages; else loading"
              >
                <nb-select
                  fullWidth
                  id="language"
                  placeholder="{{
                    'partnersPage.partnersForm.placeholders.SelectLanguge'
                      | translate
                  }}"
                  [formControl]="language$"
                  [status]="
                    c('languageIds').dirty
                      ? c('languageIds').invalid
                        ? 'danger'
                        : 'success'
                      : ''
                  "
                >
                  <nb-option
                    *ngFor="let language of languages"
                    [value]="language.id"
                    >{{ language.name }}</nb-option
                  >
                </nb-select>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="partnerTypeId"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.partnerType"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="partnerTypes$ | async as partnerTypes; else loading"
              >
                <nb-select
                  fullWidth
                  id="partnerTypeId"
                  placeholder="{{
                    'partnersPage.partnersForm.placeholders.SelectPartnerType'
                      | translate
                  }}"
                  formControlName="partnerTypeId"
                  [status]="
                    c('partnerTypeId').dirty
                      ? c('partnerTypeId').invalid
                        ? 'danger'
                        : 'success'
                      : ''
                  "
                >
                  <nb-option
                    *ngFor="let partnerType of partnerTypes"
                    [value]="partnerType.id"
                  >
                    {{ formatPartnerLabel(partnerType) }}
                  </nb-option>
                </nb-select>
              </ng-container>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="c('partnerTypeId').invalid && c('partnerTypeId').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('partnerTypeId').errors?.required"
                  translate="partnersPage.errors.partnerTypeIsRequired"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="rating"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.rating"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                type="number"
                [formControl]="cp('Rating')"
                name="rating"
                min="0"
                max="5"
                id="rating"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.Rating' | translate
                }}"
                [status]="
                  cp('Rating').dirty
                    ? cp('Rating').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Rating').invalid && cp('Rating').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="cp('Rating').invalid && cp('Rating').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="cp('Rating').errors?.min || cp('Rating').errors?.max"
                  translate="partnersPage.errors.ratingMinValue"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="email"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.email"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="email"
                name="email"
                id="email"
                placeholder="{{ 'common.placeholders.email' | translate }}"
                [status]="
                  c('email').dirty
                    ? c('email').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('email').invalid && c('email').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container *ngIf="c('email').invalid && c('email').touched">
                <span
                  class="caption status-danger"
                  *ngIf="c('email').errors?.required"
                  translate="common.errors.emailIsRequired"
                ></span>
                <span
                  class="caption status-danger"
                  *ngIf="c('email').errors?.email"
                  translate="common.errors.emailIsInvalid"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="paymentTypes"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.paymentType"
            ></label>
            <div class="col-sm-9">
              <nb-select
                fullWidth
                multiple
                id="paymentTypes"
                [disabled]="!(hasPartnerPaymentTypesPermission$ | async)"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.SelectPaymentType'
                    | translate
                }}"
                [formControl]="c('paymentTypes')"
                [status]="
                  c('paymentTypes').dirty
                    ? c('paymentTypes').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
              >
                <nb-select-label>
                  {{ selectedPaymenttypes$ | async }}
                </nb-select-label>
                <nb-option
                  *ngFor="let paymentType of PaymentTypes"
                  [value]="paymentType"
                >
                  {{ 'paymentType.' + paymentType | translate }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="c('paymentTypes').invalid && c('paymentTypes').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('paymentTypes').errors?.required"
                  translate="partnersPage.errors.paymentTypeIsRequired"
                ></span>
              </ng-container>
              <ng-container *ngIf="paymenttypeErrorMessage$ | async">
                <span class="caption status-danger">
                  {{ paymenttypeErrorMessage$ | async | translate }}
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="phoneNumber"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.phoneNumber"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="phoneNumber"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="{{
                  'common.placeholders.phoneNumber' | translate
                }}"
                [status]="
                  c('phoneNumber').dirty
                    ? c('phoneNumber').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('phoneNumber').invalid && c('phoneNumber').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6" formGroupName="properties">
          <div class="row align-items-center">
            <label
              for="externalId"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.externalId"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="filteredSubjects$ | async as subjects; else loading"
              >
                <nb-form-field>
                  <nb-icon
                    nbSuffix
                    icon="arrow-ios-downward-outline"
                    pack="eva"
                  ></nb-icon>
                  <input
                    (focus)="onFocusTypeahead($event)"
                    [formControl]="cp('ExternalId')"
                    nbInput
                    fullWidth
                    type="text"
                    id="subjectsInput"
                    class="w-100"
                    placeholder="Select external ID"
                    [inputFormatter]="subjectFormatter"
                    [resultFormatter]="subjectResultFormatter"
                    [ngbTypeahead]="filterSubjects"
                  />
                </nb-form-field>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="accountManagerId"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.accountManager"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="partnerCountry$ | async; else selectCountryFirst"
              >
                <ng-container
                  *ngIf="keyAccountManagers$ | async as keyAccountManagers"
                >
                  <nb-select
                    fullWidth
                    id="accountManagerId"
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.SelectAccountManager'
                        | translate
                    }}"
                    formControlName="accountManagerId"
                    [status]="
                      c('accountManagerId').dirty
                        ? c('accountManagerId').invalid
                          ? 'danger'
                          : 'success'
                        : ''
                    "
                  >
                    <nb-option
                      *ngFor="let keyAccountManager of keyAccountManagers"
                      [value]="keyAccountManager.id"
                    >
                      {{
                        formatAccountManagerLabel(keyAccountManager)
                      }}</nb-option
                    >
                  </nb-select>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="
                  c('accountManagerId').invalid && c('accountManagerId').touched
                "
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('accountManagerId').errors?.required"
                  translate="partnersPage.errors.accountManagerIsRequired"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div
          class="form-group col-sm-12 col-lg-6"
          *appRequirePermission="'partners.edit.isOnlinePartner'"
        >
          <div class="row align-items-center">
            <label
              for="IsOnlinePartner"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.isOnlinePartner"
            ></label>
            <div class="col-sm-9">
              <nb-checkbox
                id="IsOnlinePartner"
                [formControl]="cp('IsOnlinePartner')"
                status="primary"
              >
              </nb-checkbox>
            </div>
          </div>
        </div>
      </div>

      <p class="caption mt-2 mb-1" translate="partnersPage.titleAddress"></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="street"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.street"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="addressStreet"
                name="street"
                id="street"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.Street' | translate
                }}"
                [status]="
                  c('addressStreet').dirty
                    ? c('addressStreet').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('addressStreet').invalid && c('addressStreet').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="city"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.city"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="addressCity"
                name="city"
                id="city"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.City' | translate
                }}"
                [status]="
                  c('addressCity').dirty
                    ? c('addressCity').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('addressCity').invalid && c('addressCity').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="zip"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.zip"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="addressZip"
                name="zip"
                id="zip"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.Zip' | translate
                }}"
                [status]="
                  c('addressZip').dirty
                    ? c('addressZip').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('addressZip').invalid && c('addressZip').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="country"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.country"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="countriesFiltered$ | async as countries; else loading"
              >
                <nb-select
                  fullWidth
                  id="country"
                  placeholder="{{
                    'partnersPage.partnersForm.placeholders.SelectCountry'
                      | translate
                  }}"
                  formControlName="addressCountry"
                  [status]="
                    c('addressCountry').dirty
                      ? c('addressCountry').invalid
                        ? 'danger'
                        : 'success'
                      : ''
                  "
                >
                  <nb-option
                    *ngFor="let country of countries"
                    [value]="country.name"
                    >{{ country.name }}
                  </nb-option>
                </nb-select>
              </ng-container>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="
                  c('addressCountry').invalid && c('addressCountry').touched
                "
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('addressCountry').errors?.required"
                  translate="partnersPage.errors.countryIsRequired"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6" formGroupName="properties">
          <div class="row align-items-center">
            <label
              for="additionalInfo"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.additionalInfo"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('AdditionalInfo')"
                name="additionalInfo"
                id="additionalInfo"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.AdditionalInfo'
                    | translate
                }}"
                [status]="
                  cp('AdditionalInfo').dirty
                    ? cp('AdditionalInfo').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('AdditionalInfo').invalid && cp('AdditionalInfo').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6" formGroupName="properties">
          <div class="row align-items-center">
            <label
              for="region"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.region"
            ></label>
            <div class="col-sm-9">
              <nb-select
                fullWidth
                id="region"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.SelectRegion'
                    | translate
                }}"
                [formControl]="cp('Region')"
                [status]="
                  cp('Region').dirty
                    ? cp('Region').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
              >
                <nb-option
                  *ngFor="let region of regionsByCountry$ | async"
                  [value]="region"
                >
                  {{ region }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <span
                class="caption status-danger"
                *ngIf="invalidRegion"
                translate="partnersPage.errors.regionIsInvalid"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <p class="caption mt-2 mb-1" translate="partnersPage.kitStock"></p>
      <hr class="mt-0" />
      <div class="row">
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="minimalSupply"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.minimalSupply"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                type="number"
                [formControl]="cp('MinimalSupply')"
                name="minimalSupply"
                id="minimalSupply"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.MinimalSupply'
                    | translate
                }}"
                [status]="
                  cp('MinimalSupply').dirty
                    ? cp('MinimalSupply').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('MinimalSupply').invalid && cp('MinimalSupply').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="
                  cp('MinimalSupply').invalid && cp('MinimalSupply').touched
                "
              >
                <span
                  class="caption status-danger"
                  *ngIf="cp('MinimalSupply').errors?.required"
                  translate="partnersPage.errors.minimalSupplyIsRequired"
                ></span>
                <span
                  class="caption status-danger"
                  *ngIf="cp('MinimalSupply').errors?.min"
                  translate="partnersPage.errors.minimalSupplyMinValue"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <p
        class="caption mt-2 mb-1"
        translate="partnersPage.awbModelInformation"
      ></p>
      <hr class="mt-0" />
      <div class="row">
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="plasmaLabLocation"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.partnersForm.plasmaLabLocation"
            ></label>
            <div class="col-sm-9">
              <nb-select
                fullWidth
                id="plasmaLabLocation"
                [formControl]="cp('PlasmaLabLocation')"
                [status]="
                  cp('PlasmaLabLocation').dirty
                    ? cp('PlasmaLabLocation').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
              >
                <nb-option
                  *ngFor="let plasmaLab of plasmaLabLocations"
                  [value]="plasmaLab.value"
                >
                  {{ plasmaLab.label }}
                </nb-option>
              </nb-select>
            </div>
          </div>
        </div>
      </div>

      <ng-container *appRequirePermission="'partners.edit.onlineReports'">
        <p class="caption mt-2 mb-1">
          {{ 'partnersPage.titleOther' | translate }}
        </p>
        <hr class="mt-0" />

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="onlineReports"
              class="label col-sm-3 form-control-label mb-0"
              >{{
                'partnersPage.partnersForm.onlineReports' | translate
              }}</label
            >
            <div class="col-sm-9">
              <nb-checkbox
                id="onlineReports"
                formControlName="onlineReport"
                status="primary"
              />
            </div>
          </div>
        </div>
      </ng-container>

      <p class="caption mt-2 mb-1" translate="partnersPage.titleNotes"></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-12">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <textarea
                fullWidth
                nbInput
                formControlName="notes"
                name="notes"
                id="notes"
                placeholder="{{
                  'partnersPage.partnersForm.placeholders.notes' | translate
                }}"
                [status]="
                  c('notes').dirty
                    ? c('notes').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('notes').invalid && c('notes').touched ? true : null
                "
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button
      nbButton
      class="status-primary"
      (click)="close()"
      [class.loading]="isLoading$ | async"
      [disabled]="(isLoading$ | async) || formDirty === true"
    >
      {{ closeModalTitle | translate }}
    </button>
  </nb-card-footer>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>

<ng-template #selectCountryFirst>
  <nb-select
    disabled
    fullWidth
    placeholder="{{ 'partnersPage.selectPartnerCountryFirst' | translate }}"
  ></nb-select>
</ng-template>
