import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';

import { first, map, switchMap } from 'rxjs/operators';
import {
  LoadPartnerById,
  LoadPartnersByIds,
  LoadPartnersListAction,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { crossFieldValidator } from '@shared/validators/cross-field-validator';
import { PartnersService } from '@core/services/partners.service';
import { of } from 'rxjs';
import { PartnerDetailsComponent } from '@app/modules/partners/components/partner-details/partner-details.component';
import { Partner } from '@core/core.types';

@Component({
  selector: 'app-partner-find-by-id',
  templateUrl: './partner-find-by-id.component.html',
})
export class PartnerFindByIdComponent {
  form = this.fb.group(
    {
      ids: [''],
      subPartnerId: [''],
    },
    {
      validators: [
        crossFieldValidator('ids', 'subPartnerId', ''),
        crossFieldValidator('subPartnerId', 'ids', ''),
      ],
    }
  );
  loading = false;

  constructor(
    private dialogRef: NbDialogRef<PartnerFindByIdComponent>,
    private store: Store,
    private toastr: NbToastrService,
    private fb: FormBuilder,
    private partnersService: PartnersService,
    private dialogService: NbDialogService
  ) {}

  search() {
    this.loading = true;

    this.partnersService
      .getFilteredEntities({
        filters: new Map(
          Object.entries(this.form.value).filter(([key, value]) => !!value)
        ),
      })
      .pipe(
        switchMap((resp) => {
          if (resp.entitiesList?.[0]?.accountManagerId) {
            return this.store
              .dispatch(new LoadPartnerById(resp.entitiesList[0].id))
              .pipe(
                switchMap(() =>
                  this.store.select(PartnersState.getPartnerById)
                ),
                map((findById) => findById(resp.entitiesList[0].id)),
                first()
              );
          } else {
            throw null;
          }
        })
      )
      .subscribe({
        next: (partner) => {
          if (partner?.accountManagerId) {
            this.openDetails(partner);
            this.dialogRef.close();
          } else {
            this.toastr.danger(`Partner not found!`, 'Not found!', {
              duration: 5000,
            });
          }
          this.loading = false;
        },
        error: () => {
          this.toastr.danger(`Partner not found!`, 'Not found!', {
            duration: 5000,
          });
          this.loading = false;
        },
      });
  }

  dismiss() {
    this.dialogRef.close();
  }

  openDetails(partner: Partner) {
    this.dialogService.open(PartnerDetailsComponent, {
      context: {
        partnerId: partner.id ? partner.id : '',
      },
    });
  }
}
