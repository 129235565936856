import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FIRST_LEVEL_PARTNER_TYPES,
  MOMENT_DATE_TIME_FORMAT,
} from '@app/app.constants';
import { PartnerDialogComponent } from '@app/modules/partners/components/partner-dialog/partner-dialog.component';
import { PartnerEmployeesDialogComponent } from '@app/modules/partners/components/partner-employees-dialog/partner-employees-dialog.component';
import {
  AccountManager,
  Language,
  ModalMode,
  Partner,
  Properties,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { AccountManagerState } from '@core/store/account-managers/account-managers.state';
import { KitsState } from '@core/store/kits/kits.state';
import { PantheonState } from '@core/store/pantheon/pantheon.state';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { PartnersState } from '@core/store/partners/partners.state';
import { LoadProductConfigurationById } from '@core/store/product-configuration/product-configuration.actions';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { WarningService } from '@shared/components/warning/warning-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import moment from 'moment';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { first, map, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject as PantheonSubject } from '../../../@core/core.types';
import {
  PartnerDialogContext,
  PartnerProductDialogContext,
  PlasmaLabLocations,
} from '../../partner.types';
import { PartnersUtilsService } from '../../partners-utils.service';
import { SetPartnersProductConfigurations } from '../../store/partner-product-configurations/partner-product-configurations.action';
import { PartnerBundleDialogComponent } from '../partner-bundle-dialog/partner-bundle-dialog.component';
import { PartnerProductDialogComponent } from '../partner-product-dialog/partner-product-dialog.component';
import { LanguageState } from '@core/store/languages/languages.state';

@Component({
  selector: 'app-partner-details',
  templateUrl: 'partner-details.component.html',
  styleUrls: ['partner-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerDetailsComponent implements OnInit, OnDestroy {
  @Select(LanguageState.getLanguages) languages$: Observable<Language[]>;
  partnerType$: Observable<string>;
  averageMonthlySupply$: Observable<number>;
  storage$: Observable<number>;
  subject$: Observable<PantheonSubject>;
  partner$: Observable<Partner>;

  MOMENT_DATE_TIME_FORMAT = MOMENT_DATE_TIME_FORMAT;
  moment = moment;

  PartnerType = FIRST_LEVEL_PARTNER_TYPES;

  protected plasmaLabLocations = PlasmaLabLocations;

  private log = new Logger(this.constructor.name);
  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly partnerIdSubject = new ReplaySubject<string>(1);

  constructor(
    private partnerUtils: PartnersUtilsService,
    private store: Store,
    private translateService: TranslateService,
    private nbDialogService: NbDialogService,
    private confirmService: ConfirmService,
    private warningService: WarningService,
    private nbDialogRef: NbDialogRef<PartnerDetailsComponent>
  ) {
    this.partner$ = this.partnerIdSubject.pipe(
      switchMap((partnerId) => this.partnerUtils.getPartnerById$(partnerId))
    );

    this.partnerType$ = this.partner$.pipe(
      switchMap((partner) =>
        this.store
          .select(PartnerTypeState.getFirstLevelPartnerTypes)
          .pipe(
            map(
              (partnerTypes) =>
                partnerTypes.find(
                  (partnerType) => partnerType.id === partner.partnerTypeId
                ).type
            )
          )
      )
    );

    this.storage$ = this.partner$.pipe(
      switchMap((partner) =>
        this.store
          .select(KitsState.getFreePartnerKitsCount)
          .pipe(map((getKitsCountById) => getKitsCountById(partner.id)))
      )
    );

    this.averageMonthlySupply$ = this.partner$.pipe(
      switchMap((partner) =>
        this.store
          .select(PartnersState.getPartnerAverageTestsPerMonth)
          .pipe(map((getMonthlySupplyById) => getMonthlySupplyById(partner.id)))
      )
    );

    this.subject$ = this.partner$.pipe(
      switchMap((partner) =>
        this.store
          .select(PantheonState.getSubjectById)
          .pipe(map((findById) => findById(partner.externalId)))
      )
    );

    this.partner$.pipe(takeUntil(this.unsubscribe$)).subscribe((partner) => {
      partner.productConfigurations.map((pc: string) =>
        this.store.dispatch(new LoadProductConfigurationById(pc))
      );
      this.store.dispatch(
        new SetPartnersProductConfigurations(
          partner.id,
          partner.productConfigurations
        )
      );
    });
  }

  @Input()
  set partnerId(value: string) {
    this.partnerIdSubject.next(value);
  }

  ngOnInit(): void {
    this.log.debug('ngOnInit');
  }

  ngOnDestroy() {
    this.log.debug('ngOnDestroy');
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      delete this.unsubscribe$;
    }
  }

  resolveAccountManagerById$(
    accountManagerId: string
  ): Observable<AccountManager> {
    this.log.debug('resolveAccountManagerById');

    if (accountManagerId) {
      return this.store.select(AccountManagerState.getAccountManagerById).pipe(
        map((accountManagerFilter) => accountManagerFilter(accountManagerId)),
        share()
      );
    }
    return of(null);
  }

  resolveLineManagerBySubAccountManagerId$(
    accountManagerId: string
  ): Observable<AccountManager> {
    this.log.debug('resolveLineManagerBySubAccountManagerId');

    if (accountManagerId) {
      return this.store
        .select(AccountManagerState.getLineManagerBySubManager)
        .pipe(
          map((accountManagerFilter) => accountManagerFilter(accountManagerId)),
          share()
        );
    }
    return of(null);
  }

  resolvePropertyByKey(properties: Properties[], key: string): Properties {
    this.log.debug('resolvePropertyByKey');

    if (properties) {
      return properties.find((p) => p.key === key);
    }
  }

  resolveRating(properties: Properties[]): number {
    this.log.debug('resolveRating');

    if (properties) {
      const ratingProperty = properties.find((p) => p.key === 'Rating');
      if (ratingProperty == null) {
        return 0;
      } else {
        return parseInt(ratingProperty.value, 10);
      }
    }
  }

  onEditEmployees(event: MouseEvent, partner: Partner) {
    this.nbDialogService.open(PartnerEmployeesDialogComponent, {
      context: {
        partner,
      } as any,
    });
  }

  onEditPartner(event: MouseEvent, partner: Partner) {
    this.nbDialogService.open(PartnerDialogComponent, {
      context: {
        modalMode: ModalMode.Edit,
        editItem: partner,
        detailsDialogRef: this.nbDialogRef,
      } as PartnerDialogContext as any,
    });
  }

  addProduct(event: MouseEvent, partner: Partner) {
    this.log.debug('add product');
    event.preventDefault();
    if (partner.address && partner.address.country) {
      this.nbDialogService.open(PartnerProductDialogComponent, {
        context: {
          partner,
        } as PartnerProductDialogContext as any,
      });
    } else {
      this.warningService
        .showWarning({
          title: '',
          message: this.translateService.instant(
            'partnersPage.selectPartnerCountryFirst'
          ),
        })
        .subscribe();
    }
  }

  close() {
    this.partner$.pipe(first()).subscribe((partner) => {
      if (
        (partner.subPartners.length && partner.bundleConfigurations.length) ||
        partner.properties.find(
          (property) =>
            property.key === 'IsOnlinePartner' && property.value === 'True'
        )
      ) {
        this.nbDialogRef.close();
      } else {
        this.confirmService
          .confirm({
            message: this.translateService.instant(
              'partnersPage.missingInformationConfirm.description',
              { partnerName: partner.name }
            ),
            title: this.translateService.instant(
              'partnersPage.missingInformationConfirm.title'
            ),
          })
          .pipe(takeUntil(this.unsubscribe$), take(1))
          .subscribe((confirmOpt) => {
            if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
              this.nbDialogRef.close();
            }
          });
      }
    });
  }

  resolvePropertyValue(properties: Properties[], key: string): boolean {
    this.log.debug('resolvePropertyValue');
    const foundedProperty = this.resolvePropertyByKey(properties, key);

    if (foundedProperty && foundedProperty.value) {
      return /true/i.test(foundedProperty.value);
    }
  }

  protected addBundle(event: MouseEvent, partner: Partner) {
    this.log.debug('add bundle');
    event.preventDefault();
    if (partner.address && partner.address.country) {
      this.nbDialogService.open(PartnerBundleDialogComponent, {
        context: {
          partner,
        } as any,
      });
    } else {
      this.warningService
        .showWarning({
          title: '',
          message: this.translateService.instant(
            'partnersPage.selectPartnerCountryFirst'
          ),
        })
        .subscribe();
    }
  }

  protected getPlasmaLabLocationLabel(value: string) {
    return this.plasmaLabLocations.find((p) => p.value === value)?.label;
  }
}
