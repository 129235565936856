import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from '@app/modules/@core/services/authentication.service';
import { ProductsService } from '@app/modules/@core/services/products.service';
import { NotificationProfilesListContext } from '@app/modules/notification-profiles/notification-profile.types';
import { NotificationProfilesListComponent } from '@app/modules/notification-profiles/notification-profiles-list/notification-profiles-list.component';
import {
  ModalMode,
  Partner,
  PartnerType,
  TemplateType,
} from '@core/core.types';
import { ExportersUtilsService } from '@core/services/exporters-utils.service';
import { Logger } from '@core/services/logger.service';
import { LoadBundleConfigurationByIds } from '@core/store/bundle-configuration/bundle-configuration.actions';
import { BundleConfigurationsState } from '@core/store/bundle-configuration/bundle-configuration.state';
import { LoadNotificationProfilesMultiAction } from '@core/store/notification-profiles/notification-profiles.actions';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import {
  ActivatePartnerAction,
  DeactivatePartnerAction,
  LoadPartnersByIds,
  RemovePartnerDoctorAction,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { getPartnerServiceName, Util } from '@core/utils/core.util';
import { NbDialogService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import {
  DataTableActionsLocation,
  DataTableActionType,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { WarningService } from '@shared/components/warning/warning-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  share,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { DoctorDialogContext } from '../../partner.types';
import { PartnersUtilsService } from '../../partners-utils.service';
import { DoctorDialogComponent } from '../doctor-dialog/doctor-dialog.component';
import { LanguageState } from '@core/store/languages/languages.state';

const log = new Logger('PartnerDoctorsListComponent');

@UntilDestroy()
@Component({
  selector: 'app-partners-doctors-list',
  templateUrl: './partner-doctors-list.component.html',
  styleUrls: ['./partner-doctors-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerDoctorsListComponent implements OnInit {
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('emailTemplate', { static: true }) emailTemplate: TemplateRef<any>;
  @ViewChild('partnerTypeTemplate', { static: true })
  partnerTypeTemplate: TemplateRef<any>;

  @Input() withActions = false;

  selectedEmployees$ = new BehaviorSubject<Partner[]>([]);

  isLoading = false;
  dataTableConfiguration: DataTableConfiguration;
  partner$: Observable<Partner>;
  rows$: Observable<Partner[]>;
  partnerTypes$: Observable<PartnerType[]>;

  private readonly log = new Logger(this.constructor.name);
  private partnerId$ = new ReplaySubject<string>(1);
  private partner: Partner;
  private doctorNotificationsEmailsMap: {
    [doctorId: string]: Observable<string>;
  } = {};

  constructor(
    private store: Store,
    private nbDialogService: NbDialogService,
    private confirmService: ConfirmService,
    private translateService: TranslateService,
    private exportersUtilsService: ExportersUtilsService,
    private productsService: ProductsService,
    private warningService: WarningService,
    private authService: AuthenticationService,
    private partnersUtilsService: PartnersUtilsService
  ) {
    this.partnerTypes$ = this.store.select(
      PartnerTypeState.getPartnerTypesList
    );
    this.partner$ = this.partnerId$.pipe(
      distinctUntilChanged(),
      switchMap((partnerId) => {
        if (partnerId) {
          return this.store.select(PartnersState.getPartnerById).pipe(
            map((partnerFilter: (partnerId: string) => Partner) =>
              partnerFilter(partnerId)
            ),
            tap((partner) => (this.partner = partner))
          );
        }
        return of(null);
      })
    );
    this.rows$ = this.partner$.pipe(
      switchMap(
        (partner) =>
          this.partnersUtilsService
            .getPartnerDoctors$(partner)
            .pipe(first(), startWith(partner.subPartners.map(() => null)))
        // Starts with nulls, so empty list with placeholders is shown
      ),
      debounceTime(100),
      switchMap((doctors) => {
        let profilesIds = [];
        for (const doctor of doctors) {
          if (doctor?.notificationProfiles?.length) {
            profilesIds = [...profilesIds, ...doctor.notificationProfiles];
          }
        }
        return this.store
          .dispatch(new LoadNotificationProfilesMultiAction(profilesIds, false))
          .pipe(switchMap(() => of(doctors)));
      }),
      debounceTime(100)
    );
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('partnerId')
  set _partnerId(partnerId: string) {
    this.partnerId$.next(partnerId);
  }

  ngOnInit(): void {
    combineLatest([
      this.authService.hasRequiredPermission$(
        'partners.doctors.selection.multi'
      ),
      this.authService.hasRequiredPermission$(
        'partners.doctors.selection.single'
      ),
    ]).subscribe(([multi, single]) => {
      let selectionMode = DataTableSelectionType.NONE;
      if (this.withActions) {
        if (multi) {
          selectionMode = DataTableSelectionType.MULTI;
        } else if (single) {
          selectionMode = DataTableSelectionType.SINGLE;
        }
      }

      this.dataTableConfiguration = {
        title: '',
        selectionMode,
        tableHeadClasses: this.withActions ? 'table-secondary' : '',
        actionsLocation: DataTableActionsLocation.TOP,
        addAction: this.withActions
          ? {
              name: 'partnersPage.tooltips.createNewDoctor',
              action: this.onCreateDoctor.bind(this),
              icon: 'plus-outline',
              type: DataTableActionType.BUTTON,
              permission: 'partners.details.doctors.add',
              disabled: () =>
                (!this.partner.productConfigurations.length &&
                  Util.PropertiesToObject(this.partner.properties)
                    .isonlinepartner === 'True') ||
                (!this.partner.bundleConfigurations.length &&
                  Util.PropertiesToObject(this.partner.properties)
                    .isonlinepartner === 'False'),
            }
          : null,
        additionalActions: this.withActions
          ? [
              {
                name: 'partnersPage.tooltips.doctorsExport',
                action: this.onDoctorsExport.bind(this),
                icon: 'book-open-outline',
                permission: 'partners.doctors.buttons.export',
                disabled: (doctors) => !doctors.length,
                type: DataTableActionType.BUTTON,
              },
            ]
          : [],
        singleActions: this.withActions
          ? [
              {
                name: 'Notification profiles',
                action: this.openNotificationProfiles.bind(this),
                icon: 'email-outline',
                permission: 'partners.doctors.row.buttons.notificationProfiles',
                disabled: (doctor) => doctor?.deactivated,
                type: DataTableActionType.BUTTON,
              },
              {
                name: 'Edit employee',
                action: this.editDoctor.bind(this),
                icon: 'edit-outline',
                permission: 'partners.doctors.row.buttons.edit',
                disabled: (doctor) => doctor?.deactivated,
                type: DataTableActionType.BUTTON,
              },
              {
                name: 'partnersPage.tooltips.deactivateDoctor',
                action: this.toggleDoctorDeactivation.bind(this),
                icon: 'shield-off-outline',
                permission: 'partners.doctors.row.buttons.activateDeactivate',
                invisible: (doctor) => doctor?.deactivated,
                type: DataTableActionType.BUTTON,
              },
              {
                name: 'partnersPage.tooltips.activateDoctor',
                action: this.toggleDoctorDeactivation.bind(this),
                icon: 'shield-outline',
                permission: 'partners.doctors.row.buttons.activateDeactivate',
                invisible: (doctor) => !doctor?.deactivated,
                type: DataTableActionType.BUTTON,
              },
              {
                name: 'Remove employee',
                action: this.removeDoctor.bind(this),
                icon: 'close-outline',
                permission: 'partners.doctors.row.buttons.remove',
                disabled: (doctor) => doctor?.deactivated,
                type: DataTableActionType.BUTTON,
              },
            ]
          : [],
        columns: [
          {
            label: 'Name',
            propertyTemplate: this.nameTemplate,
          },
          {
            label: 'Type',
            propertyTemplate: this.partnerTypeTemplate,
          },
          {
            label: 'Emails',
            propertyTemplate: this.emailTemplate,
          },
          {
            label: 'Username',
            property: 'email',
          },
        ],
        rows$: this.rows$,
      };
    });
  }

  partnerType$(id) {
    return this.partnerTypes$.pipe(
      map((types) => types.find((type) => type.id === id).type)
    );
  }

  async removeDoctor(event: MouseEvent, doctor: Partner) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isLoading) {
      return false;
    }
    const confirmOpt = await this.confirmService
      .confirm({
        title: 'partnersPage.confirmDoctorRemoveTitle',
        message: this.translateService.instant(
          'partnersPage.confirmDoctorRemoveMessage',
          { doctor: doctor.name }
        ),
      })
      .pipe(take(1))
      .toPromise();
    if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
      this.isLoading = true;
      this.store
        .dispatch(
          new RemovePartnerDoctorAction(
            this.partner.id,
            doctor.id,
            doctor.userAccounts[0].id
          )
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({ error: (err) => log.error(err) });
    }
  }

  onCreateDoctor(event: MouseEvent) {
    this.log.debug('onCreateDoctor');

    const partnerRegion = this.partner.properties.find(
      (p) => p.key === 'Region'
    );
    event.preventDefault();
    this.getFirstPartnerProduct()
      .pipe(first())
      .subscribe((firstPartnerProduct) => {
        const serviceName = firstPartnerProduct
          ? getPartnerServiceName([firstPartnerProduct])
          : null;
        this.nbDialogService.open(DoctorDialogComponent, {
          context: {
            partnerId: this.partner.id,
            partner: this.partner,
            modalMode: ModalMode.Create,
            partnerRegion: partnerRegion ? partnerRegion.value : '',
            partnerLanguage: this.store.selectSnapshot(
              LanguageState.getLanguageById
            )(this.partner.languageIds[0]).name,
            serviceName,
          } as DoctorDialogContext as any,
        });
      });
  }

  getFirstPartnerProduct() {
    if (
      Util.PropertiesToObject(this.partner.properties).isonlinepartner ===
      'True'
    ) {
      return this.productsService
        .getProductConfiguration(this.partner.productConfigurations[0])
        .pipe(switchMap((pc) => this.productsService.getProduct(pc.productId)));
    } else {
      return this.store
        .dispatch(
          new LoadBundleConfigurationByIds([
            this.partner.bundleConfigurations[0],
          ])
        )
        .pipe(
          switchMap(() =>
            this.store
              .select(BundleConfigurationsState.getBundleConfigurationById)
              .pipe(
                map((getById) => getById(this.partner.bundleConfigurations[0]))
              )
          ),
          switchMap((bundleConfiguration) =>
            this.store
              .select(ProductsListState.getBundle)
              .pipe(map((getById) => getById(bundleConfiguration.bundleId)))
          ),
          switchMap((bundle) =>
            this.store
              .select(ProductsListState.getProduct)
              .pipe(map((getById) => getById(bundle.productIds[0])))
          )
        );
    }
  }

  editDoctor(event: MouseEvent, doctor: Partner) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isLoading) {
      return false;
    }
    const partnerRegion = this.partner.properties.find(
      (p) => p.key === 'Region'
    );

    this.getFirstPartnerProduct()
      .pipe(first())
      .subscribe((firstPartnerProduct) => {
        const serviceName = getPartnerServiceName([firstPartnerProduct]);

        this.nbDialogService.open(DoctorDialogComponent, {
          context: {
            partnerId: this.partner.id,
            partner: this.partner,
            modalMode: ModalMode.Edit,
            editItem: doctor,
            partnerRegion: partnerRegion ? partnerRegion.value : '',
            partnerLanguage: this.store.selectSnapshot(
              LanguageState.getLanguageById
            )(this.partner.languageIds[0]).name,
            serviceName,
          } as DoctorDialogContext as any,
        });
      });
  }

  async toggleDoctorDeactivation(event: MouseEvent, doctor: Partner) {
    this.log.debug('toggle doctor deactivation');
    event.preventDefault();
    event.stopPropagation();

    const deactivate = !doctor.deactivated;

    if (this.isLoading) {
      return false;
    }
    const confirmOpt = await this.confirmService
      .confirm({
        title: deactivate
          ? 'partnersPage.confirmDoctorDeactivationTitle'
          : 'partnersPage.confirmDoctorReactivationTitle',
        message: this.translateService.instant(
          deactivate
            ? 'partnersPage.confirmDoctorDeactivationMessage'
            : 'partnersPage.confirmDoctorReactivationMessage',
          { doctorName: doctor.name }
        ),
      })
      .pipe(take(1))
      .toPromise();
    if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
      this.isLoading = true;
      this.store
        .dispatch(
          deactivate
            ? new DeactivatePartnerAction(doctor.id)
            : new ActivatePartnerAction(doctor.id)
        )
        .pipe(
          take(1),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(() => {
          this.store.dispatch(new LoadPartnersByIds([doctor.id], true));
        });
    }
  }

  onSelectedRowsChange(rows: Partner[]) {
    this.selectedEmployees$.next(rows);
  }

  async onDoctorsExport(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const doctors = this.selectedEmployees$.value;
    const doctorsIds: string[] = doctors.map((d) => d.id);
    if (doctorsIds.length > 0) {
      this.exportersUtilsService.exportDoctors(doctorsIds);
    }
  }

  async openNotificationProfiles(event: MouseEvent, doctor: Partner) {
    event.preventDefault();
    event.stopPropagation();
    this.getFirstPartnerProduct()
      .pipe(first())
      .subscribe((firstPartnerProduct) => {
        if (!firstPartnerProduct) {
          this.warningService
            .showWarning({
              title: 'notificationProfilesPage.partnerProductsWarningTitle',
              message: this.translateService.instant(
                'notificationProfilesPage.partnerProductsWarningMessage'
              ),
            })
            .subscribe();
          return;
        }
        const serviceName = getPartnerServiceName([firstPartnerProduct]);
        this.nbDialogService.open(NotificationProfilesListComponent, {
          context: {
            doctor,
            partner: this.partner,
            serviceName,
          } as NotificationProfilesListContext as any,
        });
      });
  }

  resolveSubPartnerById$(subPartnerId: string): Observable<Partner> {
    if (subPartnerId) {
      return this.store.select(PartnersState.getPartnerById).pipe(
        map((filterById) => filterById(subPartnerId)),
        share()
      ) as Observable<any>;
    }
    return of(null);
  }

  resolveDoctorNotificationEmails$(doctor: Partner): Observable<string> {
    if (!doctor || !doctor.id) {
      return;
    }
    if (!this.doctorNotificationsEmailsMap[doctor.id]) {
      this.log.debug('resolve doctor notifications emails');
      this.doctorNotificationsEmailsMap[doctor.id] = this.partnersUtilsService
        .getDoctorNotificationProfiles(doctor.id)
        .pipe(
          debounceTime(100),
          map((nps) => {
            const emails = [];
            if (nps && nps.length) {
              for (const np of nps) {
                if (
                  np?.name === TemplateType.CustomerInviteToOnlineReport ||
                  np?.name === TemplateType.PatientConsent
                ) {
                  continue;
                }
                if (np?.recipients && np?.recipients?.length) {
                  for (const recipient of np.recipients) {
                    emails.push(recipient.recipient);
                  }
                }
              }
              return emails.filter((v, i, a) => a.indexOf(v) === i).join(', ');
            }
          })
        );
    }
    return this.doctorNotificationsEmailsMap[doctor.id];
  }
}
