<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'partnersPage.bundleForm.editBundle' | translate }}
    </p>
    <nb-icon (click)="dismiss()" icon="close" />
  </nb-card-header>
  <nb-card-body>
    <div class="row mb-1 align-items-end">
      <p class="caption mb-1 col-9">
        {{ 'partnersPage.titleBundles' | translate: { partner: partner.name } }}
      </p>
    </div>
    <hr class="mt-0 mb-3" />
    <form [formGroup]="bundleForm" novalidate>
      <div class="row">
        <div class="form-group col-sm-12">
          <div class="d-flex">
            <div class="flex-fill">
              @if (countryBundles$ | async; as bundles) {
                @if (bundles.length > 0) {
                  <nb-select
                    #selectBundle
                    fullWidth
                    id="bundle"
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.SelectBundle'
                        | translate
                    }}"
                    formControlName="bundle"
                    [status]="
                      c('bundle').dirty
                        ? c('bundle').invalid
                          ? 'danger'
                          : 'success'
                        : ''
                    "
                  >
                    @for (bundle of bundles; track bundle) {
                      <nb-option [value]="bundle">{{ bundle.name }}</nb-option>
                    }
                  </nb-select>
                }

                @if (bundles.length === 0) {
                  <nb-select
                    disabled
                    fullWidth
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.noBundlesAvailable'
                        | translate
                    }}"
                  />
                }
              } @else {
                <ng-template *ngTemplateOutlet="loading" />
              }
            </div>
            <button
              nbButton
              class="ms-2"
              nbTooltip="{{
                'partnersPage.tooltips.assignBundleToPartner' | translate
              }}"
              nbTooltipPlacement="left"
              status="primary"
              type="submit"
              [disabled]="
                isLoading ||
                ((countryBundles$ | async) &&
                  (countryBundles$ | async)?.length === 0) ||
                c('bundle').invalid
              "
              (click)="assignBundle($event, c('bundle').value)"
            >
              <nb-icon icon="plus-outline" />
            </button>
          </div>
          <div class="row align-items-center mb-2">
            @if (
              (countryBundles$ | async) && (countryBundles$ | async)?.length > 0
            ) {
              <div class="col-sm-12">
                @if (c('bundle').invalid && c('bundle').touched) {
                  @if (c('bundle').errors?.required) {
                    <span class="caption status-danger">
                      {{ 'partnersPage.errors.bundleIsRequired' | translate }}
                    </span>
                  }
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <app-data-table [configuration]="dataTableConfiguration" />
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dismiss()">Close</button>
  </nb-card-footer>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>

<ng-template #nameTemplate let-row="row">
  {{ row.bundle?.name }}
  @if (row.bundle?.deactivated) {
    <span
      class="badge bg-warning ms-2"
      [nbTooltip]="'partnersPage.tooltips.bundleDeactivated' | translate"
    >{{ 'partnersPage.infoBadges.deactivated' | translate }}
    </span>
  }

  @if (!(isBundleAvailable(row.bundle?.id) | async)) {
    <span
      class="badge bg-danger ms-2"
      [nbTooltip]="'partnersPage.tooltips.bundleNotAvailable' | translate"
    >{{ 'partnersPage.infoBadges.notAvailable' | translate }}
    </span>
  }
</ng-template>

<ng-template #loadingColumn> -- </ng-template>
