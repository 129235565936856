import { Component, Input } from '@angular/core';
import { Partner } from '@core/core.types';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-partner-employees-dialog',
  templateUrl: './partner-employees-dialog.component.html',
  styleUrls: ['./partner-employees-dialog.component.scss'],
})
export class PartnerEmployeesDialogComponent {
  @Input() partner: Partner;

  constructor(
    private dialogRef: NbDialogRef<PartnerEmployeesDialogComponent>
  ) {}

  close() {
    this.dialogRef.close();
  }
}
