import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Bundle, Partner, PaymentType } from '@core/core.types';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { Observable, ReplaySubject, map, switchMap, take } from 'rxjs';
import { PartnersUtilsService } from '../../partners-utils.service';

@UntilDestroy()
@Component({
  selector: 'app-partner-bundle-list',
  templateUrl: './partner-bundle-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerBundleListComponent implements OnInit, OnChanges {
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('endUserPriceTemplate', { static: true })
  endUserPriceTemplate: TemplateRef<any>;
  @ViewChild('doctorsFeePriceTemplate', { static: true })
  doctorsFeePriceTemplate: TemplateRef<any>;
  @ViewChild('wholesalePriceTemplate', { static: true })
  wholesalePriceTemplate: TemplateRef<any>;
  @ViewChild('onlineFeePriceTemplate', { static: true })
  onlineFeePriceTemplate: TemplateRef<any>;

  @Input() public selectedPartner: Partner;

  protected selectedPartner$ = new ReplaySubject<Partner>(1);
  protected dataTableConfiguration: DataTableConfiguration;
  protected isLoading = false;

  constructor(
    private store: Store,
    private partnersUtilsService: PartnersUtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPartner) {
      this.selectedPartner$.next(this.selectedPartner);
    }
  }

  ngOnInit(): void {
    const rows = this.store.select(PartnersState.getPartnerById).pipe(
      map(
        (getPartnerById) =>
          getPartnerById(this.selectedPartner.id).bundleConfigurations
      ),
      untilDestroyed(this),
      switchMap((bundleConfigurations: string[]) =>
        this.partnersUtilsService.getPartnerBundles(bundleConfigurations)
      ),
      map((bundles) => bundles.sort((a, b) => (a.name > b.name ? 1 : -1)))
    );
    const partnerPaymentTypes = this.selectedPartner.paymentTypes;

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      columns: [
        {
          label: 'partnersPage.tableColumns.name',
          propertyTemplate: this.nameTemplate,
        },
        partnerPaymentTypes.indexOf(PaymentType.Customer1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1
          ? {
              label: 'partnersPage.tableColumns.endUserPrice',
              propertyTemplate: this.endUserPriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Customer1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1
          ? {
              label: 'partnersPage.tableColumns.doctorsFee',
              propertyTemplate: this.doctorsFeePriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Partner) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Customer2) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Online2) > -1
          ? {
              label: 'partnersPage.tableColumns.wholeSalePrice',
              propertyTemplate: this.wholesalePriceTemplate,
            }
          : null,
        partnerPaymentTypes.indexOf(PaymentType.Online1) > -1 ||
        partnerPaymentTypes.indexOf(PaymentType.Online2) > -1
          ? {
              label: 'partnersPage.tableColumns.onlineFee',
              propertyTemplate: this.onlineFeePriceTemplate,
            }
          : null,
      ].filter((column) => !!column),
      rows$: rows,
    };
  }

  protected isBundleAvailable(bundleId: string): Observable<boolean> {
    return this.store
      .select(ProductsListState.getCountryBundleList)
      .pipe(
        map((bundlesByCountry) =>
          bundlesByCountry(
            this.selectedPartner.address
              ? this.selectedPartner.address.country
              : null
          )
        )
      )
      .pipe(
        take(1),
        map(
          (countryBundles: Bundle[]) =>
            !!countryBundles.find((bundle) => bundle.id === bundleId)
        )
      );
  }
}
