<app-data-table
  [configuration]="dataTableConfiguration"
  [loading]="isLoading"
/>

<ng-template #nameTemplate let-row="row">
  {{ row.name }}
  @if (row.deactivated) {
    <span
      class="badge bg-warning ms-2"
      [nbTooltip]="'partnersPage.tooltips.bundleDeactivated' | translate"
      >{{ 'partnersPage.infoBadges.deactivated' | translate }}
    </span>
  }

  @if (!(isBundleAvailable(row.id) | async)) {
    <span
      class="badge bg-danger ms-2"
      [nbTooltip]="'partnersPage.tooltips.bundleNotAvailable' | translate"
      >{{ 'partnersPage.infoBadges.notAvailable' | translate }}
    </span>
  }
</ng-template>

<ng-template #endUserPriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    [productExternalId]="row.endUserPriceExternalId"
  />
</ng-template>
<ng-template #doctorsFeePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    [productExternalId]="row.doctorFeeExternalId"
  />
</ng-template>
<ng-template #wholesalePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    [productExternalId]="row.wholesalePriceExternalId"
  />
</ng-template>
<ng-template #onlineFeePriceTemplate let-row="row">
  <app-product-pantheon-price
    [selectedPartnerExternalId]="(selectedPartner$ | async).externalId"
    [productExternalId]="row.wholesalePriceExternalId"
  />
</ng-template>
