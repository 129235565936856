import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  NIFTY_APPLICATIONID_KEY,
  NOTIFICATION_EMAIL_SENDER,
  ServiceType,
} from '@app/app.constants';
import {
  ModalMode,
  NotificationProfileModel,
  Partner,
  RecipientModel,
  TemplateType,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { UpdateNotificationProfilesAction } from '@core/store/notification-profiles/notification-profiles.actions';
import { Util } from '@core/utils/core.util';
import { getTemplateId } from '@core/utils/partner.util';
import { NbDialogRef } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { WarningService } from '@shared/components/warning/warning-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationsProfilesUtilsService } from '../../notification-profiles-utils.service';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { environment } from '@root/environments/environment';
import {
  DataTableActionsLocation,
  DataTableActionType,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';

@Component({
  selector: 'app-notification-profile-dialog',
  templateUrl: 'notification-profile-dialog.component.html',
  styleUrls: ['notification-profile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationProfileDialogComponent implements OnInit {
  @Input() modalMode: ModalMode;
  @Input() doctor: Partner;
  @Input() partnerLanguage: string;
  @Input() serviceName: string;
  @Input() notificationProfiles: NotificationProfileModel[];
  @Input() missingNotificationProfiles: string[];

  dataTableConfiguration: DataTableConfiguration;
  rows$: BehaviorSubject<RecipientModel[]>;
  isLoading$ = new BehaviorSubject<boolean>(false);
  notificationProfileForm: any = null;
  private readonly log = new Logger(this.constructor.name);
  private unsubscribe$: Subject<void>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<any>,
    private confirmService: ConfirmService,
    private store: Store,
    private warningService: WarningService,
    private notificationsProfilesUtils: NotificationsProfilesUtilsService,
    private partnerUtils: PartnersUtilsService
  ) {}

  get modalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'notificationProfilesPage.form.createNotificationProfileTitle';
    } else if (this.modalMode === ModalMode.Edit) {
      if (this.notificationProfiles.length === 1) {
        return this.translateService.instant(
          'notificationProfilesPage.form.editNotificationProfileTitle',
          { name: `${this.notificationProfiles[0].name}` }
        );
      } else {
        return this.translateService.instant(
          'notificationProfilesPage.form.allNofificationProfilesText'
        );
      }
    }
    return '';
  }

  get closeModalTitle() {
    if (this.modalMode === ModalMode.Create) {
      return 'common.buttons.btnCreate';
    } else if (this.modalMode === ModalMode.Edit) {
      return 'common.buttons.btnSave';
    }
    return '';
  }

  private get rows(): Promise<RecipientModel[]> {
    return this.rows$.pipe(take(1)).toPromise();
  }

  private get isLoading(): Promise<boolean> {
    return this.isLoading$.pipe(take(1)).toPromise();
  }

  ngOnInit(): void {
    this.createForm(
      this.modalMode === ModalMode.Edit ? this.notificationProfiles[0] : null
    );

    const rows =
      this.missingNotificationProfiles &&
      this.missingNotificationProfiles.length
        ? []
        : this.notificationProfiles && this.notificationProfiles.length
        ? [...this.notificationProfiles[0].recipients]
        : [];
    this.rows$ = new BehaviorSubject(rows);

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          type: DataTableActionType.BUTTON,
          name: 'notificationProfilesPage.tooltips.removeRecipient',
          icon: 'close-outline',
          permission:
            'partners.doctors.notificationProfiles.buttons.editAndCreate',
          action: this.removeRecipient.bind(this),
        },
      ],
      columns: [
        {
          label: 'notificationProfilesPage.tableColumns.recipients',
          property: 'recipient',
        },
      ],
      rows$: this.rows$,
    };
  }

  createForm(notificationProfile?: NotificationProfileModel) {
    this.notificationProfileForm = this.formBuilder.group(
      Object.assign(
        {
          id: [
            notificationProfile ? notificationProfile.id : Util.CreateGuid(),
            [Validators.compose([Validators.required])],
          ],
          name: [notificationProfile ? notificationProfile.name : ''],
          templateId: [
            notificationProfile ? notificationProfile.templateId : '',
          ],
          recipient: [
            '',
            Validators.compose([Validators.pattern(Util.GetEmailPattern())]),
          ],
          recipients: [[]],
        },
        notificationProfile
          ? {
              generated: [(notificationProfile as any).generated],
            }
          : {
              applicationId: [
                NIFTY_APPLICATIONID_KEY,
                [Validators.compose([Validators.required])],
              ],
            }
      )
    );
  }

  public c(control: string): any {
    return this.notificationProfileForm.controls[control];
  }

  dismiss() {
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
    this.dialogRef.close();
  }

  async addRecipient(event: MouseEvent) {
    this.log.debug('add recipient');
    event.preventDefault();
    if ((await this.isLoading) || this.notificationProfileForm.invalid) {
      return false;
    }
    const recipient = this.notificationProfileForm.get('recipient').value;
    if (!recipient) {
      return false;
    }
    const rows = await this.rows;
    if (!rows || !rows.find((r) => r.recipient === recipient)) {
      this.notificationProfileForm.get('recipient').setValue('');
      this.rows$.next([
        ...rows,
        { recipient, recipientName: this.doctor.name },
      ]);
      this.isLoading$.next(false);
    }
  }

  async removeRecipient(event: MouseEvent, recipient: RecipientModel) {
    event.preventDefault();
    if (await this.isLoading) {
      return false;
    }
    const confirmOpt = await this.confirmService
      .confirm({
        message: this.translateService.instant(
          'notificationProfilesPage.confirmRecipientRemoveMessage',
          { recipient: recipient.recipient }
        ),
        title: 'notificationProfilesPage.confirmRecipientRemoveTitle',
      })
      .pipe(take(1))
      .toPromise();
    if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
      this.isLoading$.next(true);
      const updatedRows = [...(await this.rows)];
      const recipientIndex = updatedRows.indexOf(recipient);
      if (recipientIndex > -1) {
        updatedRows.splice(recipientIndex, 1);
        this.rows$.next(updatedRows);
        this.isLoading$.next(false);
      }
    }
  }

  async close() {
    this.log.debug('close');
    if (this.notificationProfileForm.invalid) {
      Util.MarkFormGroupTouched(this.notificationProfileForm);
      return false;
    }
    const formValue = this.notificationProfileForm.value;
    const rows = await this.rows;
    if (!rows || !rows.length) {
      await this.warningService
        .showWarning({
          title: 'notificationProfilesPage.recipientsWarningTitle',
          message: this.translateService.instant(
            'notificationProfilesPage.recipientsWarningMessage'
          ),
        })
        .toPromise();
      return;
    }
    formValue.recipients = rows;
    this.isLoading$.next(true);
    let saveItem$ = of(null);

    const sender = NOTIFICATION_EMAIL_SENDER[this.serviceName];

    const senderName = `${this.serviceName} TEST`;
    if (this.modalMode === ModalMode.Edit) {
      if (this.notificationProfiles.length > 1) {
        const confirmOpt = await this.confirmService
          .confirm({
            message: this.translateService.instant(
              'notificationProfilesPage.confirmRecipientOverwrite'
            ),
            title: 'notificationProfilesPage.confirmRecipientOverwriteTitle',
          })
          .pipe(take(1))
          .toPromise();
        if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
          saveItem$ = this.updateNotificationProfiles(
            sender,
            senderName,
            formValue
          );
        } else {
          this.isLoading$.next(false);
          return;
        }
      } else {
        saveItem$ = this.updateNotificationProfiles(
          sender,
          senderName,
          formValue
        );
      }
    } else {
      this.log.debug('create');
      if (
        this.missingNotificationProfiles &&
        this.missingNotificationProfiles.length > 0
      ) {
        try {
          await this.createNotificationProfiles(
            this.missingNotificationProfiles,
            this.serviceName,
            sender,
            senderName,
            formValue.recipients
          );
        } catch (error) {
          this.log.debug(error);
        }
      }
    }
    await saveItem$.toPromise();
    this.isLoading$.next(false);
    this.dismiss();
  }

  updateNotificationProfiles(sender, senderName, formValue) {
    this.log.debug('edit');
    const nps = [];
    for (const np of this.notificationProfiles) {
      let recipientsTemp = formValue.recipients;
      if (
        np.name === TemplateType.CustomerInviteToOnlineReport ||
        np.name === TemplateType.PatientConsent
      ) {
        recipientsTemp = [
          {
            recipientName: this.doctor.name,
            recipient: environment.resultReferenceLinkEmail,
          },
        ];
      }
      nps.push({
        id: np.id,
        sender,
        senderName,
        recipients: recipientsTemp,
        name: np.name,
        templateId: this.getTemplateIdBasedOnTemplateType(
          np.name,
          this.serviceName
        ),
        applicationId: formValue.applicationId,
      });
    }

    return this.store.dispatch(new UpdateNotificationProfilesAction(nps));
  }

  private async createNotificationProfiles(
    templateTypes: string[],
    serviceName: string,
    sender: string,
    senderName: string,
    recipients: RecipientModel[]
  ) {
    const nps = [];
    for (const templateType of templateTypes) {
      let recipientsTemp = recipients;
      if (
        templateType === TemplateType.CustomerInviteToOnlineReport ||
        templateType === TemplateType.PatientConsent
      ) {
        recipientsTemp = [
          {
            recipientName: this.doctor.name,
            recipient: environment.resultReferenceLinkEmail,
          },
        ];
      }
      nps.push({
        id: Util.CreateGuid(),
        sender,
        senderName,
        recipients: recipientsTemp,
        name: templateType,
        templateId: this.getTemplateIdBasedOnTemplateType(
          templateType,
          serviceName
        ),
        applicationId: NIFTY_APPLICATIONID_KEY,
      });
    }
    await this.notificationsProfilesUtils
      .createPartnerNotificationProfiles(this.doctor.id, nps)
      .toPromise();
  }

  private getTemplateIdBasedOnTemplateType(
    templateType: string,
    serviceName: string
  ): string {
    if (templateType === TemplateType.CustomerInviteToOnlineReport) {
      return this.partnerUtils.getResultReferenceLinkTemplateByLanguage(
        this.partnerLanguage
      );
    } else if (templateType === TemplateType.CustomerFormInvite) {
      return this.partnerUtils.getPatientFormLinkTemplateByLanguage(
        this.partnerLanguage
      );
    } else {
      return getTemplateId(serviceName);
    }
  }
}
