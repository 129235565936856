<nb-card [nbSpinner]="isLoading$ | async">
  <nb-card-header class="position-relative">
    <p class="mb-0">{{ modalTitle | translate }}</p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form
      *ngIf="doctorForm"
      class="ps-4 pe-4"
      (ngSubmit)="close()"
      [formGroup]="doctorForm"
      novalidate
    >
      <p class="caption mb-1" translate="common.titleBaseInformation"></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-sm-12 col-lg-6" formGroupName="properties">
          <div class="row align-items-center">
            <label
              for="title"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.title"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('Title')"
                name="title"
                id="title"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.title' | translate
                }}"
                [status]="
                  cp('Title').dirty
                    ? cp('Title').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Title').invalid && cp('Title').touched ? true : null
                "
              />
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="name"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.name"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="name"
                name="name"
                id="name"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.name' | translate
                }}"
                [status]="
                  c('name').dirty
                    ? c('name').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('name').invalid && c('name').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container *ngIf="c('name').invalid && c('name').touched">
                <span
                  class="caption status-danger"
                  *ngIf="c('name').errors?.required"
                  translate="common.errors.nameIsRequired"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="companyName"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.company"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('Company')"
                name="company"
                id="company"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.company' | translate
                }}"
                [status]="
                  cp('Company').dirty
                    ? cp('Company').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Company').invalid && cp('Company').touched ? true : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="phoneNumber"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.phoneNumber"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="phoneNumber"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.phoneNumber'
                    | translate
                }}"
                [status]="
                  c('phoneNumber').dirty
                    ? c('phoneNumber').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('phoneNumber').invalid && c('phoneNumber').touched
                    ? true
                    : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="c('phoneNumber').invalid && c('phoneNumber').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('phoneNumber').errors?.required"
                  translate="partnersPage.errors.phoneNumberIsRequired"
                ></span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="companyName"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.address"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('Address')"
                name="address"
                id="address"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.address' | translate
                }}"
                [status]="
                  cp('Address').dirty
                    ? cp('Address').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Address').invalid && cp('Address').touched ? true : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="companyName"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.city"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('City')"
                name="city"
                id="city"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.city' | translate
                }}"
                [status]="
                  cp('City').dirty
                    ? cp('City').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('City').invalid && cp('City').touched ? true : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="companyName"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.zip"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('Zip')"
                name="zip"
                id="zip"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.zip' | translate
                }}"
                [status]="
                  cp('Zip').dirty
                    ? cp('Zip').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Zip').invalid && cp('Zip').touched ? true : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="companyName"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.country"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                [formControl]="cp('Country')"
                name="country"
                id="country"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.country' | translate
                }}"
                [status]="
                  cp('Country').dirty
                    ? cp('Country').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Country').invalid && cp('Country').touched ? true : null
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6" formGroupName="properties">
          <div class="row align-items-center">
            <label
              for="rating"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.rating"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                type="number"
                [formControl]="cp('Rating')"
                name="rating"
                min="0"
                max="5"
                id="rating"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.rating' | translate
                }}"
                [status]="
                  cp('Rating').dirty
                    ? cp('Rating').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  cp('Rating').invalid && cp('Rating').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="cp('Rating').invalid && cp('Rating').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="cp('Rating').errors?.min"
                  translate="partnersPage.errors.ratingMinValue"
                >
                </span>
                <span
                  class="caption status-danger"
                  *ngIf="cp('Rating').errors?.max"
                  translate="partnersPage.errors.ratingMaxValue"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="partnerTypeId"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.partnerType"
            ></label>
            <div class="col-sm-9">
              <ng-container
                *ngIf="
                  employeePartnerTypes$ | async as employeePartnerTypes;
                  else loading
                "
              >
                <nb-select
                  fullWidth
                  id="partnerTypeId"
                  placeholder="{{
                    'partnersPage.doctorsForm.placeholders.partnerType'
                      | translate
                  }}"
                  formControlName="partnerTypeId"
                  [status]="
                    c('partnerTypeId').dirty
                      ? c('partnerTypeId').invalid
                        ? 'danger'
                        : 'success'
                      : ''
                  "
                >
                  <nb-option
                    *ngFor="let employeePartnerType of employeePartnerTypes"
                    [value]="employeePartnerType.id"
                  >
                    {{ formatPartnerLabel(employeePartnerType) }}</nb-option
                  >
                </nb-select>
              </ng-container>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="c('partnerTypeId').invalid && c('partnerTypeId').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('partnerTypeId').errors?.required"
                  translate="partnersPage.errors.partnerTypeIsRequired"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="sex"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.gender"
            ></label>
            <div class="col-sm-9">
              <nb-select
                fullWidth
                id="sex"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.gender' | translate
                }}"
                [formControl]="cp('Sex')"
                [status]="
                  cp('Sex').dirty
                    ? cp('Sex').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
              >
                <nb-option *ngFor="let gender of GENDERS" [value]="gender">{{
                  gender | translate
                }}</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container *ngIf="cp('Sex').invalid && cp('Sex').touched">
                <span
                  class="caption status-danger"
                  *ngIf="cp('Sex').errors?.required"
                  translate="partnersPage.errors.genderIsRequired"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <ng-container *ngIf="showDifferentRegion$ | async">
          <div class="form-group col-sm-12 col-lg-6">
            <div
              class="row align-items-center mb-3"
              *ngIf="isRegionShown"
              formGroupName="properties"
            >
              <label
                for="region"
                class="label col-sm-3 form-control-label mb-0"
                translate="partnersPage.partnersForm.region"
              ></label>
              <div class="col-sm-9">
                <ng-container
                  *ngIf="
                    partnerRegions$ | async as partnerRegions;
                    else loading
                  "
                >
                  <nb-select
                    fullWidth
                    id="region"
                    placeholder="{{
                      'partnersPage.partnersForm.placeholders.Region'
                        | translate
                    }}"
                    [formControl]="cp('Region')"
                    [status]="
                      cp('Region').dirty
                        ? cp('Region').invalid
                          ? 'danger'
                          : 'success'
                        : ''
                    "
                  >
                    <nb-option
                      *ngFor="let region of partnerRegions"
                      [value]="region"
                    >
                      {{ region }}</nb-option
                    >
                  </nb-select>
                </ng-container>
              </div>
            </div>
            <div class="row" formGroupName="region">
              <div class="col-9 offset-3">
                <nb-checkbox
                  id="differentRegion"
                  formControlName="differentRegion"
                  status="primary"
                  >Different region than partner?
                </nb-checkbox>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <p
        class="caption mt-2 mb-1"
        translate="partnersPage.titleUserAccount"
      ></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="username"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.username"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="username"
                name="username"
                id="username"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.username' | translate
                }}"
                [status]="
                  c('username').dirty
                    ? c('username').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('username').invalid && c('username').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container *ngIf="doctorUserAccount$ | async as userAccount">
                <span
                  class="caption status-danger"
                  *ngIf="userAccount.blocked"
                  translate="common.errors.userAccountBlocked"
                >
                </span>
              </ng-container>
              <ng-container
                *ngIf="c('username').invalid && c('username').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('username').errors?.required"
                  translate="common.errors.emailIsRequired"
                >
                </span>
              </ng-container>
              <ng-container
                *ngIf="c('username').invalid && c('username').touched"
              >
                <p
                  class="caption status-danger"
                  *ngIf="c('username').errors?.pattern"
                  translate="common.errors.usernameIsInvalid"
                ></p>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-lg-6">
          <div class="row align-items-center">
            <label
              for="password"
              class="label col-sm-3 form-control-label mb-0"
              translate="partnersPage.doctorsForm.password"
            ></label>
            <div class="col-sm-9">
              <input
                fullWidth
                nbInput
                formControlName="password"
                name="password"
                id="password"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.password' | translate
                }}"
                [status]="
                  c('password').dirty
                    ? c('password').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('password').invalid && c('password').touched ? true : null
                "
              />
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="offset-sm-3 col-sm-9 ps-4">
              <ng-container
                *ngIf="c('password').invalid && c('password').touched"
              >
                <span
                  class="caption status-danger"
                  *ngIf="c('password').errors?.passwordStrength"
                  translate="common.errors.passwordWeak"
                >
                </span>
                <span
                  class="caption status-danger"
                  *ngIf="c('password').errors?.required"
                  translate="common.errors.passwordIsRequired"
                >
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <p class="caption mt-2 mb-1" translate="partnersPage.titleNotes"></p>
      <hr class="mt-0" />

      <div class="row">
        <div class="form-group col-12">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <textarea
                fullWidth
                nbInput
                formControlName="notes"
                name="notes"
                id="notes"
                placeholder="{{
                  'partnersPage.doctorsForm.placeholders.notes' | translate
                }}"
                [status]="
                  c('notes').dirty
                    ? c('notes').invalid
                      ? 'danger'
                      : 'success'
                    : ''
                "
                [attr.aria-invalid]="
                  c('notes').invalid && c('notes').touched ? true : null
                "
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button
      nbButton
      *appRequirePermission="'partners.doctors.edit.submit'"
      class="status-primary"
      (click)="close()"
      [disabled]="(isLoading$ | async) || formDirty === true"
    >
      {{ closeModalTitle | translate }}
    </button>
  </nb-card-footer>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>
