import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NgModel,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Bundle, BundleConfiguration, Partner } from '@core/core.types';
import { BundleConfigurationsState } from '@core/store/bundle-configuration/bundle-configuration.state';
import {
  AssignBundleToPartner,
  UnassignBundleConfigurationFromPartner,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { NbDialogRef } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { ConfirmCloseReason } from '@shared/shared.types';
import {
  Observable,
  filter,
  finalize,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';

interface BundlePair {
  bundleConfiguration: BundleConfiguration;
  bundle: Bundle;
}

@UntilDestroy()
@Component({
  selector: 'app-partner-bundle-dialog',
  templateUrl: 'partner-bundle-dialog.component.html',
  styleUrls: ['partner-bundle-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerBundleDialogComponent implements OnInit {
  @ViewChild('selectBundle') private selectBundle: NgModel;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;

  @Input() public partner: Partner;

  protected bundleForm: FormGroup = null;
  protected dataTableConfiguration: DataTableConfiguration;
  protected countryBundles$: Observable<Bundle[]>;
  protected isLoading = false;

  private rows: Observable<BundlePair[]> = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private confirmService: ConfirmService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<any>,
    private cdRef: ChangeDetectorRef,
    private store: Store
  ) {
    this.rows = this.store.select(PartnersState.getPartnerById).pipe(
      map(
        (getPartnerById) => getPartnerById(this.partner.id).bundleConfigurations
      ),
      untilDestroyed(this),
      switchMap((bundleConfigurations: string[]) =>
        this.store
          .select(BundleConfigurationsState.getBundleConfigurationByIds)
          .pipe(
            map((getByIds) => getByIds(bundleConfigurations)),
            switchMap((bundleConfigurations: BundleConfiguration[]) =>
              this.store.select(ProductsListState.getBundle).pipe(
                map((getBundle) =>
                  bundleConfigurations
                    .map((bc: BundleConfiguration) => ({
                      bundleConfiguration: bc,
                      bundle: getBundle(bc.bundleId),
                    }))
                    .sort((a, b) => (a.bundle.name < b.bundle.name ? -1 : 1))
                )
              )
            )
          )
      )
    );
  }

  ngOnInit(): void {
    this.createForm();

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'partnersPage.tooltips.removeBundleFromPartner',
          action: this.unassignBundle.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
          permission: 'partners.details.bundles.remove',
        },
      ],
      columns: [
        {
          label: 'Bundle name',
          propertyTemplate: this.nameTemplate,
        },
      ],
      rows$: this.rows,
    };

    this.countryBundles$ = this.getBundleList();
  }

  protected dismiss(): void {
    this.dialogRef.close();
  }

  protected c(control: string): AbstractControl {
    return this.bundleForm.controls[control];
  }

  protected assignBundle(event: MouseEvent, bundle: Bundle) {
    event.preventDefault();
    if (this.isLoading) {
      return false;
    }
    this.isLoading = true;

    this.store
      .dispatch(new AssignBundleToPartner(this.partner.id, bundle.id))
      .pipe(
        first(),
        untilDestroyed(this),
        finalize(() => {
          this.cdRef.markForCheck();
          this.isLoading = false;
          this.selectBundle.reset(null);
          this.bundleForm.reset();
        })
      )
      .subscribe();
  }

  protected isBundleAvailable(bundleId: string): Observable<boolean> {
    return this.store
      .select(ProductsListState.getCountryBundleList)
      .pipe(
        map((bundlesByCountry) =>
          bundlesByCountry(
            this.partner.address ? this.partner.address.country : null
          )
        )
      )
      .pipe(
        take(1),
        map(
          (countryBundles: Bundle[]) =>
            !!countryBundles.find((bundle) => bundle.id === bundleId)
        )
      );
  }

  private createForm() {
    this.bundleForm = this.formBuilder.group({
      bundle: ['', [Validators.required]],
    });
  }

  private unassignBundle(event: MouseEvent, bundlePair: BundlePair) {
    event.preventDefault();
    if (this.isLoading) {
      return false;
    }

    this.confirmService
      .confirm({
        message: this.translateService.instant(
          'partnersPage.confirmBundleUnassignMessage',
          { bundle: bundlePair?.bundle?.name }
        ),
        title: 'partnersPage.confirmBundleUnassignTitle',
      })
      .pipe(
        untilDestroyed(this),
        first(),
        switchMap((confirmOpt) => {
          if (confirmOpt.closeReason === ConfirmCloseReason.Yes) {
            this.isLoading = true;
            return this.store
              .dispatch(
                new UnassignBundleConfigurationFromPartner(
                  this.partner.id,
                  bundlePair.bundleConfiguration.id
                )
              )
              .pipe(
                untilDestroyed(this),
                finalize(() => {
                  this.cdRef.markForCheck();
                  this.isLoading = false;
                })
              );
          }
        })
      )
      .subscribe();
  }

  private getBundleList(): Observable<Bundle[]> {
    return this.store.select(ProductsListState.getCountryActiveBundleList).pipe(
      map((countryFilter) => countryFilter(this.partner.address.country)),
      switchMap((bundles: Bundle[]) =>
        this.store.select(PartnersState.getPartnerById).pipe(
          map(
            (getPartnerById) =>
              getPartnerById(this.partner.id).bundleConfigurations
          ),
          switchMap((bundleConfigurations: string[]) => {
            if (bundleConfigurations && bundleConfigurations.length <= 0) {
              return of(bundles);
            }
            return forkJoin(
              bundleConfigurations.map((bundleConfigurationId: string) =>
                this.store
                  .select(BundleConfigurationsState.getBundleConfigurationById)
                  .pipe(
                    map((getBundleConfigurationById) =>
                      getBundleConfigurationById(bundleConfigurationId)
                    ),
                    filter(
                      (bundleConfiguration: BundleConfiguration) =>
                        !!bundleConfiguration
                    ),
                    first()
                  )
              )
            ).pipe(
              map((bundleConfigurations: BundleConfiguration[]) =>
                bundles
                  .filter(
                    (bundle) =>
                      bundleConfigurations.findIndex(
                        (bundleConfiguration) =>
                          bundleConfiguration.bundleId === bundle.id
                      ) < 0
                  )
                  .sort((a, b) => (a.name < b.name ? -1 : 1))
              )
            );
          })
        )
      )
    );
  }
}
