import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { DATEPICKER_DATE_FORMAT } from '../../../../app.constants';
import { Product } from '../../../@core/core.types';
import { Logger } from '../../../@core/services/logger.service';
import {
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';

@Component({
  selector: 'app-partner-product-price-dialog',
  templateUrl: './partner-product-price-dialog.component.html',
  styleUrls: ['./partner-product-price-dialog.component.scss'],
})
export class PartnerProductPriceDialogComponent implements OnInit {
  @Input() product: Product;
  @Input() selectedPartnerExternalId: string;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;
  @ViewChild('endUserPriceTemplate', { static: true })
  endUserPriceTemplate: TemplateRef<any>;
  @ViewChild('doctorsFeePriceTemplate', { static: true })
  doctorsFeePriceTemplate: TemplateRef<any>;
  @ViewChild('wholesalePriceTemplate', { static: true })
  wholesalePriceTemplate: TemplateRef<any>;

  DATEPICKER_DATE_FORMAT = DATEPICKER_DATE_FORMAT;

  products$ = new BehaviorSubject<Product[]>(null);
  selectedPartnerExternalId$ = new ReplaySubject<string>(1);

  isLoading$ = new BehaviorSubject<boolean>(false);

  changeProductPriceForm: UntypedFormGroup = null;
  enduserPriceField: UntypedFormControl;
  doctorsFeePriceField: UntypedFormControl;
  wholesalePriceField: UntypedFormControl;
  fromField: UntypedFormControl;
  toField: UntypedFormControl;

  dataTableConfiguration: DataTableConfiguration;

  private log = new Logger('PartnerProductPriceDialogComponent');

  constructor(
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<any>,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.products$.next([this.product]);
    this.selectedPartnerExternalId$.next(this.selectedPartnerExternalId);

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      columns: [
        {
          label: 'partnersPage.tableColumns.name',
          property: 'name',
        },
        {
          label: 'partnersPage.tableColumns.endUserPrice',
          propertyTemplate: this.endUserPriceTemplate,
        },
        {
          label: 'partnersPage.tableColumns.doctorsFee',
          propertyTemplate: this.doctorsFeePriceTemplate,
        },
        {
          label: 'partnersPage.tableColumns.wholeSalePrice',
          propertyTemplate: this.wholesalePriceTemplate,
        },
      ],
      rows$: this.products$,
    };

    this.createForm();
  }

  createForm() {
    this.log.debug('create form');

    this.enduserPriceField = new UntypedFormControl('');
    this.doctorsFeePriceField = new UntypedFormControl('');
    this.wholesalePriceField = new UntypedFormControl('');
    this.fromField = new UntypedFormControl('');
    this.toField = new UntypedFormControl('');

    this.changeProductPriceForm = this.formBuilder.group({
      enduserPrice: this.enduserPriceField,
      doctorsFeePrice: this.doctorsFeePriceField,
      wholesalePrice: this.wholesalePriceField,
      from: this.fromField,
      to: this.toField,
    });
  }

  dismiss(event: MouseEvent) {
    event.preventDefault();

    this.dialogRef.close();
  }

  public c(controlName: string) {
    return this.changeProductPriceForm.controls[
      controlName
    ] as UntypedFormControl;
  }

  onSave() {
    this.log.debug('on save');
    this.isLoading$.next(true);
  }

  checkPantheonPriceValue(value: any) {
    this.log.debug('checkPantheonPriceValue');
  }
}
